<template>
    <div>
        <div class="main-slide real-slider common-padding comm-slider new-slider">
            <carousel :autoplay="2000" :wrap-around="true" :items-to-show="1" :snapAlign="snapAlignment"
                :breakpoints="breakpoints1" :transition="500">
                <slide v-for="(items, index) in MetaSlider" :key="index">
                    <div class="position-relative metaslider w-100">
                        <div class="main-bg-img">
                            <img :src="require(`../../assets/images/${items.image}`)" alt=""
                                class="w-100 object-fit-cover" height="650">
                        </div>

                        <div class="main-content">
                            <div
                                class="position-absolute blogOverlay metOverlay w-100 transition top-0 h-650 py-4 -fluid px-10 text-start d-flex flex-column justify-content-center">
                                <div class="content_wraper">
                                    <h1 class="mt-5 fw-semibold">
                                        {{ items.title }}
                                    </h1>
                                    <p class="mb-5">
                                        {{ items.para }}
                                    </p>
                                    <!-- <div>
                                    <button
                                        class="bgGradient_btn border-btn position-relative border-0 rounded-4 z-1 w-auto text-white">{{
                                        items.buttontext }}</button>
                                </div> -->
                                </div>
                            </div>
                        </div>
                        <pagination />
                    </div>
                </slide>
            </carousel>
        </div>

        <Divider />

        <section class="video-section">
            <div class="container">
                <div class="row">
                    <div class="main-title text-center mb-5">
                        <h2 class="mb-2 fw-semibold">24/7 Real Estate</h2>
                        <p class="fs-6 opacity-75">Discover 24/7 real estate services. Whether buying or selling, our
                            platform offers <br /> round-the-clock support for all your property needs.</p>
                    </div>
                    <div class="col-lg-12">
                        <div class="main-video-section">
                            <video autoplay="" muted width="100%" height="500" loop="" playsinline=""
                                poster="../../assets/images/real-estate.mp4" style="border-radius: 12px;"
                                class="object-fit-cover m-auto">
                                <source src="../../assets/images/real-estate.mp4" type="video/mp4">
                            </video>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <Divider />

        <section class="stats-section">
            <div class="container">
                <div class="row gap-0 row-gap-4">
                    <div class="col-lg-12">
                        <div class="main-title text-center mb-5">
                            <h2 class="mb-2 fw-semibold">The Future of Homebuying is Now</h2>
                            <p class="fs-6 opacity-75">Experience the future of homebuying today. Our platform offers
                                innovative solutions <br /> and personalized support for your digital property journey.
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4" v-for="item in StatSection" :key="item">
                        <div class="prperty-card video-wrapper">
                            <div class="mb-3">
                                <img :src="require('../../assets/images/' + item.image)" alt="" width="48" height="48">
                            </div>
                            <div class="content">
                                <h4 class="fw-semibold">{{ item.title }}</h4>
                                <p class="fs-6 opacity-75">{{ item.description }}</p>
                                <a :href="item.link" class="text-decoration-none text-white">{{ item.linkText }} <img
                                        src="../../assets/icons/arrow.svg" alt=""></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 bottom-text video-wrapper rounded-pill text-center py-3">Over $4 billion in
                        transactions processed with Heyden Coin partners across the world</div>
                </div>
            </div>
        </section>

        <Divider />

        <section class="property-section">
            <div class="container">
                <div class="row gap-0 row-gap-4">
                    <div class="col-lg-12">
                        <div class="main-title text-center mb-5">
                            <h2 class="mb-2 fw-semibold">Featured Listings</h2>
                            <p class="fs-6 opacity-75">Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit
                                nostrum nesciunt iure reiciendis.</p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4" v-for="(property, index) in properties" :key="index">
                        <div class="card-listing video-wrapper">
                            <div class="property-img mb-3">
                                <img :src="require('../../assets/images/' + property.image)" alt=""
                                    class="w-100 rounded-4 object-fit-cover" height="250">
                            </div>
                            <div class="">
                                <h3 class="mb-2 fw-semibold">{{ property.price }}</h3>
                                <p class="mb-3 fs-7">{{ property.address }}</p>
                                <div class="property-detail d-flex gap-3 align-items-center mb-4">
                                    <span class="d-flex gap-2 align-items-center fw-medium fs-7">
                                        <img src="../../assets/images/bed.svg" alt="">
                                        {{ property.bedrooms }} Bed
                                    </span>
                                    <span class="d-flex gap-2 align-items-center fw-medium fs-7">
                                        <img src="../../assets/images/bath.svg" alt="">
                                        {{ property.bathrooms }} Bath
                                    </span>
                                    <span class="d-flex gap-2 align-items-center fw-medium fs-7">
                                        <img src="../../assets/images/room.svg" alt="">
                                        {{ property.area }}
                                    </span>
                                </div>
                                <div class="button-listing">
                                    <button class="w-100 bgGradient_btn rounded-4 border-0 text-white fw-medium">
                                        view listing
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="view-listing d-flex justify-content-center mt-4">
                        <button class="text-uppercase fs-7 bg-white rounded-4 border-0 px-4  text-black fw-semibold">
                            view More listing
                        </button>
                    </div>

                </div>
            </div>
        </section>

        <Divider />

        <section class="testimonial-section comm-slider">
            <div class="container">
                <div class="row gap-0 row-gap-4">
                    <div class="col-lg-12">
                        <div class="main-title text-center mb-5">
                            <h2 class="mb-2 fw-semibold">From our Clients</h2>
                            <p class="fs-6 opacity-75">Testimonials from satisfied clients showcase our commitment to
                                excellence and customer satisfaction.</p>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <carousel :autoplay="3500" :wrap-around="true" :items-to-show="1" :snapAlign="snapAlignment"
                            :breakpoints="breakpoints1" :transition="500">
                            <slide v-for="(testimonial, index) in testimonials" :key="index">
                                <div class="testimonial video-wrapper w-100 p-4">
                                    <div class="main-image mb-3">
                                        <img :src="require('../../assets/images/' + testimonial.image)" alt=""
                                            width="70" height="70" class="rounded-circle object-fit-cover">
                                    </div>
                                    <div class="content">
                                        <h3 class="mb-0 fw-semibold mb-2">{{ testimonial.name }}</h3>
                                        <p>{{ testimonial.position }}</p>
                                        <i class="testimonial-content mx-auto lh-lg mb-4 d-inline-block">
                                            " {{ testimonial.quote }} "
                                        </i>
                                        <pagination />
                                    </div>
                                </div>
                            </slide>
                        </carousel>
                    </div>
                </div>
            </div>
        </section>

        <Divider />

        <section class="featured-section comm-slider mb-5">
            <div class="container">
                <div class="row gap-0 row-gap-4">
                    <div class="col-lg-12">
                        <div class="main-title text-center mb-5">
                            <h2 class="mb-2 fw-semibold">Featured by Heyden Coin</h2>
                            <p class="fs-6 opacity-75">Highlighted listings curated by Heyden Coin showcase premier
                                virtual real estate opportunities.</p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-6" v-for="(item, index) in FeaturedData" :key="index">
                        <div
                            class="featured video-wrapper d-flex gap-3 align-items-center justify-content-center flex-column flex-lg-row">
                            <div class="img">
                                <img :src="require('../../assets/images/' + item.image)" :alt="item.alt" width="150">
                            </div>
                            <div class="content">
                                <h4 class="fw-semibold">{{ item.title }}</h4>
                                <p class="fs-7">{{ item.description }}</p>
                                <a :href="item.link" class="text-decoration-none text-white fs-7 fw-semibold">Learn
                                    more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, } from 'vue3-carousel'
import Divider from '../../components/Divider.vue';

export default {
    name: 'MasterComponents',
    data() {
        return {
            MetaSlider: [
                {
                    image: 'real1.jpg',
                    title: 'Digital Lands',
                    para: 'Explore vast, immersive landscapes in our digital world. Own, develop, and trade digital assets to shape your virtual empire.',
                    buttontext: 'Read the Manifesto'
                },
                {
                    image: 'real2.jpg',
                    title: 'Virtual Properties',
                    para: 'Discover prime virtual real estate. Invest in digital properties and build your dream virtual world. Your gateway to infinite possibilities.',
                    buttontext: 'Read the Heyden Coin'
                },
                {
                    image: 'real3.jpg',
                    title: 'Web Estates',
                    para: 'Explore exclusive virtual territories. Build your online empire with premium web estates. Your gateway to digital expansion and innovation.',
                    buttontext: 'Read the Wearables'
                },
                {
                    image: 'real4.jpg',
                    title: 'Cyber Lands',
                    para: 'Embark on an adventure through vast cyber lands. Own virtual territory and build your digital empire in this dynamic landscape.',
                    buttontext: 'Read the Wearables'
                },
                {
                    image: 'real5.jpg',
                    title: 'Digital Acres',
                    para: 'Explore expansive digital acres ripe for development. Own virtual land and shape your online legacy in this vast digital realm.',
                    buttontext: 'Read the Wearables'
                },
            ],
            StatSection: [
                {
                    title: 'Transaction Platform',
                    description: 'Streamline your transactions with our secure platform. Buy, sell, and trade digital assets effortlessly, backed by advanced security features and reliable support.',
                    link: 'javascript:void(0);',
                    linkText: 'Learn more',
                    image: 'gaming-1.svg'
                },
                {
                    title: 'Title & Escrow',
                    description: 'Simplify your title and escrow process with our trusted services. We ensure smooth transactions, secure transfers, and peace of mind for all parties involved.',
                    link: 'javascript:void(0);',
                    linkText: 'Learn more',
                    image: 'gaming-2.svg'
                },
                {
                    title: 'Get Started Today',
                    description: 'Ready to begin your journey? Get started today with our user-friendly platform. Explore, buy, sell, and invest in digital assets effortlessly.',
                    link: 'javascript:void(0);',
                    linkText: 'Learn more',
                    image: 'gaming-3.svg'
                },
            ],
            properties: [
                {
                    image: "real1.jpg",
                    price: "$ 9,250,000",
                    address: "123 Main Street, Anytown, USA",
                    bedrooms: 4,
                    bathrooms: 4,
                    area: 500
                },
                {
                    image: "real2.jpg",
                    price: "$ 5,850,000",
                    address: "456 Elm Street, Springfield, IL 62701, United States",
                    bedrooms: 6,
                    bathrooms: 3,
                    area: 800
                },
                {
                    image: "real3.jpg",
                    price: "$ 8,250,000",
                    address: "789 Oak Avenue, Sunnydale, CA 90210, USA",
                    bedrooms: 1,
                    bathrooms: 2,
                    area: 100
                },
                {
                    image: "real4.jpg",
                    price: "$ 45,50,000",
                    address: "101 Pine Street, Lakeside, MN 56468, United States",
                    bedrooms: 3,
                    bathrooms: 3,
                    area: 200
                },
                {
                    image: "real5.jpg",
                    price: "$ 9,58,000",
                    address: "246 Maple Drive, Riverdale, NY 10463, USA",
                    bedrooms: 4,
                    bathrooms: 2,
                    area: 250
                },
                {
                    image: "real1.jpg",
                    price: "$ 3,65,000",
                    address: "1500 Orange Avenue, Coronado, CA 92118, US",
                    bedrooms: 2,
                    bathrooms: 2,
                    area: 139
                },
            ],
            testimonials: [
                {
                    name: "— Samantha L",
                    position: "NFT Buyer",
                    quote: "Heyden Coin has completely transformed my approach to digital assets. The seamless transactions and innovative NFT staking options have opened up new opportunities for me. I’m excited to see what’s next!",
                    image: "testimonial1.webp"
                },
                {
                    name: "— David M",
                    position: "Game Buyer",
                    quote: "As an early adopter, I’ve been impressed by Heyden Coin’s vision and execution. The community is vibrant, and the rewards from staking NFTs have been amazing. It’s a project that truly delivers.",
                    image: "testimonial2.png"
                },
                {
                    name: "— Emily R",
                    position: "Metaverse Buyer",
                    quote: "The Heyden Coin ecosystem is unlike anything I’ve experienced in the crypto world. From the intuitive DApp to the exciting metaverse development, it’s clear they are building something extraordinary.",
                    image: "testimonial3.png"
                },
                {
                    name: "— Carlos V",
                    position: "NFT Buyer",
                    quote: "I joined Heyden Coin to explore their unique NFT offerings and ended up staying for the strong community and promising growth. It’s an investment I’m confident in and excited about.",
                    image: "testimonial5.webp"
                },
                {
                    name: "— Priya K",
                    position: "NFT Buyer",
                    quote: "Heyden Coin is paving the way for the future of digital finance. The innovative strategies and engaging platform have made it easy for me to participate and benefit from the evolving blockchain landscape.",
                    image: "testimonial4.png"
                },
            ],
            FeaturedData: [
                {
                    title: 'MetaAgents',
                    description: 'Empowering avatars to navigate digital realms with expert guidance.',
                    image: 'featured1.webp',
                    alt: 'Featured Image 1',
                    link: ''
                },
                {
                    title: 'NFT Marketplace',
                    description: 'Discover, buy, and sell unique digital assets securely online.',
                    image: 'featured2.webp',
                    alt: 'Featured Image 1',
                    link: ''
                },
                {
                    title: 'Blog',
                    description: 'Insights, tips, and updates on virtual real estate trends.',
                    image: 'featured3.webp',
                    alt: 'Featured Image 1',
                    link: ''
                },
                {
                    title: 'Resource Center',
                    description: 'Your hub for guides, tools, and resources on virtual real estate.',
                    image: 'featured4.webp',
                    alt: 'Featured Image 1',
                    link: ''
                },
            ]
        }
    },
    components: {
        Carousel,
        Slide,
        Divider,
        Pagination,
        // Navigation,
    },
    methods: {
    }
}

</script>

<style scoped>
.blogOverlay {
    background: linear-gradient(180deg, #000 3.65%, transparent 23.76%),
        linear-gradient(90deg, #050e15 7.06%, transparent 75.49%),
        rgba(211, 211, 211, .2) 0 -12.611px/116.806% 131.998% no-repeat;
    padding: 80px 30px;
}

.content_wraper {
    padding: 40px;
    max-width: 509px;
    border-radius: 32px;
    backdrop-filter: blur(5px);
    background-color: #69696952;
}


.main-video-section video {
    filter: brightness(0.8);
}

.testimonial-content {
    max-width: 600px;
}

/* .featured {
    transition: all 0.5s ease-in-out;
}
.featured:hover {
    box-shadow: rgb(127 34 170) 0px 8px 24px;
} */
</style>
<template>
    <div>
        <div class="meta-slider main-slide common-padding comm-slider new-slider">
            <carousel :items-to-show="1" :snapAlign="snapAlignment" :breakpoints="breakpoints1" :transition="500"
                :autoplay="2000" :wrap-around="true">
                <slide v-for="(items, index) in MetaSlider" :key="index">
                    <div class="position-relative metaslider w-100 h-100">
                        <template v-if="isImage(items.image)">
                            <div class="main-bg-img">
                                <img :src="require(`../../assets/images/${items.image}`)" alt=""
                                    class="w-100 object-fit-cover">
                            </div>
                        </template>
                        <template v-else-if="isVideo(items.image)">
                            <video class="w-100 object-fit-cover" autoplay loop height="100%">
                                <source :src="require(`../../assets/images/${items.image}`)" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </template>

                        <div
                            class="position-absolute blogOverlay metOverlay w-100 transition top-0 h-100 p-4 text-start d-flex flex-column justify-content-center">
                            <h1 class="mt-5 fw-semibold">
                                {{ items.title }}
                            </h1>
                            <p class="mb-5">
                                {{ items.para }}
                            </p>
                            <!-- <div>
                                <button
                                    class="bgGradient_btn border-btn position-relative border-0 rounded-4 z-1 w-auto text-white">{{
                                    items.buttontext }}</button>
                            </div> -->
                        </div>
                    </div>
                </slide>
                <template #addons>
                    <navigation />
                    <pagination />
                </template>
            </carousel>
        </div>

        <Divider />
        <section class="metaverse-card">
            <div class="container">
                <div class="row gap-0 row-gap-4">
                    <div class="col-lg-12">
                        <div class="top-tile d-flex flex-column align-items-center mb-5 main-title">
                            <h4 class="fs-6 text-uppercase">In the Enchanted Realm of Nova Thera</h4>
                            <h2 class="mb-2 section-title fw-semibold text-capitalize">water, earth, fire and air</h2>
                            <p class="mb-0">Select your clan and journey with care</p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4" v-for="(slide, index) in videoWrap" :key="index">
                        <div class="video-wrapper h-100">
                            <!-- <video autoplay="" muted width="100%" loop="" playsinline=""  style="border-radius: 12px;">
                                <source :src="require(`../../assets/images/${slide.video}`)" type="video/mp4">
                            </video> -->
                            <!-- <div v-html="slide.video"></div> -->
                            <video class="w-100 object-fit-cover rounded-4" autoplay loop muted height="366">
                                <source :src="require(`../../assets/images/${slide.video}`)" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                            <h4 class="mb-3">{{ slide.title }}</h4>
                            <p class="m-0">{{ slide.text }}</p>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <Divider />

        <section class="">
            <div class="container">
                <div class="row mb-4">
                    <div class="col-lg-12">
                        <div class="top-tile d-flex flex-column align-items-center mb-5 main-title">
                            <h2 class="section-title text-capitalize fw-semibold">Revenue streams</h2>
                            <p class="mb-0 fs-6 opacity-75">Our Current and Upcoming Products</p>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="video-wrapper revenue-video">
                            <div class="top-tile d-flex flex-column align-items-center mb-5">
                                <h2 class="mb-0 section-title text-capitalize fw-semibold">mid-core games </h2>
                                <p class="mb-0">Our bigger long term releases</p>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="vider__wrap rounded-4 overflow-hidden position-relative h-100">
                                        <video width="100%" height="100%" loop autoplay muted>
                                            <source src="../../assets/images/game-3.mp4" type="video/mp4">
                                            Your browser does not support the video tag.
                                        </video>
                                        <!-- <img src="../../assets/images/game3.gif" alt="" class="w-100" height="325"> -->
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-6">
                                    <div class="vider__wrap rounded-4 overflow-hidden position-relative h-100">
                                        <!-- <img src="../../assets/images/game1.gif" alt="" class="w-100" height="325"> -->
                                        <video width="100%" height="100%" loop autoplay muted>
                                            <source src="../../assets/images/game-2.mp4" type="video/mp4">
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-videos row mb-4">
                    <div class="col-lg-6">
                        <div class="video-wrapper">
                            <div class="top-tile d-flex flex-column align-items-center mb-5">
                                <h2 class="mb-0 section-title text-capitalize fw-semibold">casual games </h2>
                                <p class="mb-0">Easy fun in virtual realms.</p>
                            </div>
                            <div class="vider__wrap rounded-4 overflow-hidden ">
                                <video width="100%" height="100%" loop autoplay muted>
                                    <source src="../../assets/images/game-1.mp4" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                                <!-- <img src="../../assets/images/game-1.mp4" alt="" class="w-100" height="325"> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="video-wrapper h-100">
                            <div class="top-tile d-flex flex-column align-items-center mb-5">
                                <h2 class="mb-0 section-title text-capitalize fw-semibold">physical merchadise</h2>
                                <p class="mb-0">Playful items for everyday enjoyment.</p>
                            </div>
                            <div class="rounded-4 overflow-hidden position-relative row gap-0 row-gap-4">
                                <div class="col-sm-6  col-lg-6">
                                    <div class="img-wrapper bg-white rounded-4 p-3">
                                        <img src="https://dfr1mir7zesy.cloudfront.net/landing/Merch_NomNom.gif" alt=""
                                            width="100%">
                                    </div>
                                </div>
                                <div class="col-sm-6  col-lg-6">
                                    <div class="img-wrapper bg-white rounded-4 p-3">
                                        <img src="https://dfr1mir7zesy.cloudfront.net/landing/Merch_Kevin.gif" alt=""
                                            width="100%">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="video-wrapper revenue-video">
                            <div class="top-tile d-flex flex-column align-items-center mb-5">
                                <h2 class="mb-0 section-title text-capitalize fw-semibold">comic and animation</h2>
                                <p class="mb-0">Graphic adventures in boundless realms.</p>
                            </div>
                            <div class="rounded-4 overflow-hidden position-relative row gap-0 row-gap-4">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="imageWrapper rounded-4">
                                        <img src="../../assets/images/comic1.jpg" alt="" width="100%" height="325"
                                            class="rounded-4 object-fit-cover">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-6">
                                    <div class="imageWrapper rounded-4 ">
                                        <img src="../../assets/images/comic2.jpg" alt="" width="100%" height="325"
                                            class="rounded-4 object-fit-cover">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Divider />

        <section>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="middle_wrap">
                            <div class="top-tile d-flex flex-column align-items-center mb-5 text-center main-title">
                                <h2 class="section-title text-capitalize fw-semibold">Explore, Connect, Conquer</h2>
                                <p class="mb-0 fs-6 opacity-75">Embark on epic quests, forge alliances, and conquer
                                    digital
                                    realms in our immersive <br /> metaverse gaming experience. Join the adventure
                                    today!</p>
                            </div>
                            <div class="meta-bg">
                                <img src="../../assets/images/meta-bg.jpg" alt="" width="100%"
                                    class="rounded-4 object-fit-cover" height="600">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Divider />


        <section class="pb-5">
            <div class="container">
                <div class="row gap-0 row-gap-4">
                    <div class="col-sm-6  col-lg-6">
                        <div class="video-wrapper h-100">
                            <div class="top-tile d-flex flex-column align-items-center mb-5">
                                <h2 class="mb-0 section-title text-capitalize">Events </h2>
                            </div>
                            <div class="events overflow-hidden">
                                <div class="newImage-wrap mb-3">
                                    <img src="../../assets/images/events.jpg" alt="" width="100%"
                                        class="object-fit-cover rounded-4">
                                </div>
                                <a class="text-decoration-none text-white " href="#">View Event <img
                                        src="../../assets/images/arrow.svg" alt=""></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6  col-lg-6">
                        <div class="video-wrapper h-100">
                            <div class="top-tile d-flex flex-column align-items-center mb-5">
                                <h2 class="mb-0 section-title text-capitalize">Blogs </h2>
                            </div>
                            <div class="blog overflow-hidden">
                                <div class="newImage-wrap mb-3">
                                    <img src="../../assets/images/slider-3.jpg" alt="" width="100%"
                                        class="object-fit-cover rounded-4">
                                </div>
                                <div class="blog-detail">
                                    <h3 class="title fs-6 fw-semibold">PIXELMON DEVELOPMENT UPDATE SEPTEMBER</h3>
                                    <p>Game Updates • October 3, 2023 </p>
                                    <a class="text-decoration-none text-white" href="#">View post <img
                                            src="../../assets/images/arrow.svg" alt=""></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import Divider from '../../components/Divider.vue';
// import ChartCircle from '../../components/ChartCircle.vue';

export default {
    name: 'MasterComponents',
    data() {
        return {
            MetaSlider: [
                {
                    image: 'game-img-4.jpg',
                    title: 'MetaVerse Hub',
                    para: 'Explore immersive virtual worlds, connect with a global community, unleash your creativity in an endless digital universe of possibilities.',
                    buttontext: 'Read the Manifesto'
                },
                {
                    image: 'metaverse-2.jpg',
                    title: 'Virtual Reality',
                    para: 'Explore vast virtual realms, connect with people worldwide, and express yourself in limitless ways. Your digital adventure awaits!',
                    buttontext: 'Read the Heyden Coin'
                },
                {
                    image: 'metaverse-1.jpg',
                    title: 'Digital Realm',
                    para: 'Explore virtual realms, connect worldwide, and craft your digital identity. Immerse in boundless experiences and shape the future together.',
                    buttontext: 'Read the Wearables'
                }
            ],
            videoWrap: [
                {
                    video: 'Hero_Pixelmon.mp4',
                    title: 'Dragonfire Odyssey',
                    text: "Embark on fiery quests in Dragonfire Odyssey's immersive realm."
                },
                {
                    video: 'Hero_Trainer.mp4',
                    title: 'Lady Lethal',
                    text: "In Lady Lethal's metaverse, she reigns supreme"
                },
                {
                    video: 'Hero_Serum.mp4',
                    title: 'Virtual Vanguard',
                    text: 'Where gamers unite to conquer virtual worlds.'
                }
            ],

        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        Divider,
        // ChartCircle,
    },
    methods: {
        isImage(image) {
            const extensions = ['.jpg', '.jpeg', '.png', '.svg'];
            const ext = image.substring(image.lastIndexOf('.')).toLowerCase();
            return extensions.includes(ext);
        },
        isVideo(image) {
            return image.toLowerCase().endsWith('.mp4');
        }
    }
}

</script>

<style scoped>
.main-bg-img {
    height: 650px;
}

.metOverlay {
    z-index: 99;
    max-width: 605px;
    left: 81px;
}

.metaslider::before {
    background: linear-gradient(90deg, #18141a 0, transparent 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 70%;
    z-index: 2;
}

.main-slide .carousel__slide,
.newImage-wrap {
    background: #00000063;
}

.metaslider img,
.newImage-wrap img {
    mix-blend-mode: overlay;
    height: 100%;
}

.meta-bg img {
    filter: brightness(0.9);
}

/* section */


.metaverse-card {
    background: #000000;
    background-image: linear-gradient(to bottom, rgb(0 0 0 / 92%), rgb(117 19 93 / 0%)), url('../../assets/images/landing-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 784px;
}

.section-title text-uppercase {
    font-size: 48px;
}


.vider__wrap::before {
    content: '';
    background: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(0deg, rgba(0, 0, 0, .5) 3.65%, transparent 81.19%);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0 !important;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    padding: 1rem;
}

.img-wrapper,
.imageWrapper {
    background: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(0deg, rgba(0, 0, 0, .5) 3.65%, transparent 81.19%);
}

.imageWrapper img {
    mix-blend-mode: color;
}

@media screen and (max-width:768px) {
    .divider-line {
        margin: 30px;
    }

    p {
        font-size: 1rem !important;
    }
}
</style>
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/auth/HomeView.vue";
import MetaverseViewVue from "@/views/auth/MetaverseView.vue";
import GameView from "@/views/auth/GameView.vue";
import VirtualrealEstateView from "@/views/auth/VirtualrealEstateView.vue";
import NftcosmosView from "@/views/auth/NftcosmosView.vue";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    meta: { authOnly: false }
  },
  {
    path: "/metaverse",
    name: "MetaverseView",
    component: MetaverseViewVue,
    meta: { authOnly: false }
  },
  {
    path: "/games",
    name: "GameView",
    component: GameView,
    meta: { authOnly: false }
  },
  {
    path: "/virtual-real-estate",
    name: "VirtualrealEstateView",
    component: VirtualrealEstateView,
    meta: { authOnly: false }
  },
  {
    path: "/nft-cosmos",
    name: "NftcosmosView",
    component: NftcosmosView,
    meta: { authOnly: false }
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


export default router;

<template>
  <div class="new__accordion">
    <div class="accordion" id="accordionExample">
  <div class="accordion-item border-dark rounded-3" v-for="(item, index) in faqItems" :key="index">
    <h2 class="accordion-header">
      <button class="accordion-button text-white fw-semibold fs-6" type="button" data-bs-toggle="collapse" :data-bs-target="'#' + item.id" :aria-expanded="item.expanded" :aria-controls="item.id">
        {{ item.title }}
      </button>
    </h2>
    <div :id="item.id" class="accordion-collapse collapse" :class="{ 'show': item.expanded }" data-bs-parent="#accordionExample">
      <div class="accordion-body text-white" v-html="item.content"></div>
    </div>
  </div>
</div>

  </div>
</template>

<script>
export default {
  name: 'FaqComponent',
  data() {
    return {
    faqItems: [
      {
        id: "collapseOne",
        title: "What is Heyden Coin?",
        content: "Heyden Coin is a next-generation cryptocurrency designed to facilitate seamless digital transactions and offer unique opportunities through blockchain technology. It combines innovation with a strong community focus to create a robust and rewarding ecosystem.",
        expanded: true 
      },
      {
        id: "collapseTwo",
        title: "How can I purchase Heyden Coin?",
        content: "You can purchase Heyden Coin on decentralized and centralized exchanges once it is listed. Early supporters can also acquire Heyden Coin by participating in our Genesis Minting event or by staking Heyden Coin NFTs.",
        expanded: false 
      },
      {
        id: "collapseThree",
        title: "What benefits do Heyden Coin NFTs offer?",
        content: "Heyden Coin NFTs provide exclusive access to staking opportunities, allowing holders to earn Heyden Coin rewards. They also unlock additional perks within the Heyden Coin ecosystem, including potential access to metaverses and other digital assets.",
        expanded: false 
      },
      {
        id: "collapsefour",
        title: "How can I participate in the Heyden Coin community?",
        content: "You can join the Heyden Coin community by purchasing NFTs, staking them to earn rewards, participating in governance, and engaging with other members on our social platforms. Being an active member can also lead to exclusive opportunities and rewards.",
        expanded: false 
      },
      {
        id: "collapsefive",
        title: "When will Heyden Coin be available for trading?",
        content: "Heyden Coin will be available for trading on decentralized exchanges (DEX) and centralized exchanges (CEX) following the completion of our roadmap's early phases. Stay tuned for official announcements regarding the exact launch dates.",
        expanded: false 
      }
    ]
    };
  },
  methods: {
  
  }
};
</script>

<style scoped>

:is(.new__accordion .accordion-button, .new__accordion .accordion-body){
    /* border: 1px solid hsla(0, 0%, 100%, .15); */
    background: #171717;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: none;
}

.new__accordion .accordion-button:focus {
    border-color: #a531a9;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.new__accordion .accordion-button::after{
  filter: brightness(0) saturate(100%) invert(100%) sepia(7%) saturate(7419%) hue-rotate(231deg) brightness(126%) contrast(96%);
}
</style>

<template>
  <div>
    <header>
      <nav class="navbar navbar-expand-xl py-2">
    <div class="container-fluid px-2 px-md-5">
      <a class="navbar-brand" href="/">
        <img src="../assets/artelligence-banner-image-loge.png" alt="" width="140">
      </a>
      <button class="navbar-toggler custom-bar p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" @click="toggleMenu">
        <img v-if="!isCollapsed" src="../assets/icons/menu.svg" alt="">
        <img v-else src="../assets/icons/close.svg" alt="">
      </button>
      <div class="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
        <ul class="navbar-nav align-items-center mb-5 mb-xl-0">
          <li v-for="(navItem, index) in navItems" :key="index"  @click="setActive(index)">
            <router-link class="nav-link text-white px-3 d-flex align-items-center gap-1 fw-medium fs-6" :class="{ 'active': activeIndex === index }" aria-current="page"
             :to="navItem.link">
              <img :src="require('../assets/icons/'+ navItem.icon )" alt="">{{ navItem.label }}
            </router-link>
          </li>
          <li >
            <a href="/#aboutsection" class="nav-link text-white px-3 d-flex align-items-center gap-1 fw-medium fs-6" 
            >
            <img src="../assets/icons/about.svg" alt="">
              About
          </a>
          </li>
          <li >
            <a href="/pdf/Heyden PPT.pdf" class=" text-white px-3 d-flex align-items-center gap-1 fw-medium fs-6 " 
            >
              <img src="../assets/icons/reward.svg" alt="">
              Rewards
            </a>
          </li>
        </ul>

        <div class="d-flex gap-4 align-items-center justify-content-center">
       
          <a href="https://app.heydencoin.com/login" class="text-decoration-none bgGradient_btn rounded-4 border-0 text-white fw-medium px-5">
            Plan A
          </a>

          <a href="https://app2.heydencoin.com/login" target="_blank" class="whitepaper fw-semibold text-decoration-none bgGradient_btn text-white rounded-4 text-white fw-medium px-5">
           Plan B
          </a>

          <!-- <a href="https://fastpay.world/" class="text-decoration-none btn__new rounded-4 text-white fw-medium">
            Buy SNT
          </a> -->
          <!-- <img src="../assets/icons/about.svg" alt=""> -->
        </div>
      </div>
    </div>
  </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      isCollapsed: false,
      activeIndex: null,
      navItems: [
        { label: 'Metaverse', icon: 'metaverse.svg', link: '/metaverse' },
        { label: 'Games', icon: 'games.svg', link: '/games' },
        { label: 'Real estate', icon: 'real-estate.svg', link: '/virtual-real-estate' },
        { label: 'NFT Cosmos', icon: 'cosmos.svg', link: '/nft-cosmos' },
        // { label: 'About', icon: 'about.svg', link: '/nft-cosmos' },
        // { label: 'Rewards', icon: 'reward.svg', link: '/nft-cosmos' }


      ]
    };
  },
  methods: {
    setActive(index) {
      this.activeIndex = index;
    },
    toggleMenu() {
      this.isCollapsed = !this.isCollapsed;
    }
  }
};
</script>
<style scoped>
.whitepaper{
    background: #0000;
    padding: 12px 24px;
    border: 1px solid #e4aa39;
    color: #e4aa39 !important;
}
.whitepaper:hover{
    background: #0000;
    padding: 12px 24px;
    border: 1px solid #e4aa39;
    color: #e4aa39;
}
.gap-32{
  gap: 32px;
}

header .nav-link.active{
    background-color: #ffffff38;
    color: rgb(255, 255, 255) !important;
    border-radius: 50px;
}

.btn__new{
    border: 1px solid #FFf;
    padding: 12px 35px;
    transition: all 0.5s ease-in-out;
    min-height: 50px;
}
</style>

<template>
  <div>
    <div class="divider-line d-flex justify-content-center align-items-center position-relative">
          <svg width="52" height="17" viewBox="0 0 52 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33 0L34.5721 6.9279L51.5 8.5L34.5721 10.0721L33 17L31.4279 10.0721L0.5 8.5L31.4279 6.9279L33 0Z" fill="#EFE6E1"></path></svg>
      </div>
  </div>
</template>

<script>
export default {
  name: 'DividerComponent',
}
</script>
<style scoped>


.divider-line{
    margin: 80px 116px;
}
.divider-line:after,
.divider-line:before {
    content: "";
    background: hsla(0,0%,85%,.2);
    position: absolute;
    top: 8px;
    height: 1px;
    width: 100%;
}

.divider-line:after{
  right: 0;
}
</style>

<template>
  <div class="pt-5">
    <ul class="cst__roadmap">
      <li style="--accent-color:#a58d36">
        <div class="date">2024</div>
        <div class="descr px-0 px-md-4 p-4">
          <h3 class="mb-3 fw-semibold">Phase 1</h3>
          <div class=" d-flex flex-column gap-3 ps-3">
            <span>&#8226; Project ideation</span>
            <span>&#8226; MVP Development</span>
            <span>&#8226; Heyden Coin NFT design ideation</span>
          </div>
        </div>
      </li>
      <li style="--accent-color:#9f731f">
        <div class="date">2024</div>
        <div class="descr px-0 px-md-4 p-4">
          <h3 class="mb-3 fw-semibold">Phase 2</h3>
          <div class="d-flex flex-column gap-3 ps-3">
            <span>&#8226; DApp development</span>
            <span>&#8226; Early bird whitelist opens</span>
            <span>&#8226; Initial marketing efforts</span>
            <span>&#8226; Partnership announcements</span>
            <span>&#8226; Collaboration with DEX and blockchain partners</span>
          </div>
        </div>
      </li>
      <li style="--accent-color:#daa131">
        <div class="date">2024</div>
        <div class="descr px-0 px-md-4 p-4"> 
          <h3 class="mb-3 fw-semibold">Phase 3</h3>
          <div class=" d-flex flex-column gap-3 ps-3">
            <span>&#8226; NFT minting goes live</span>
            <span>&#8226; NFT staking launches</span>
            <span>&#8226; Affiliate program activation</span>
            <span>&#8226; Heyden genesis minting begins</span>
          </div>
       
        </div>
      </li>
      <li style="--accent-color:#ac933b">
        <div class="date">2024</div>
        <div class="descr px-0 px-md-4 p-4">
          <h3 class="mb-3 fw-semibold">Phase 4</h3>
          <div class="d-flex flex-column gap-3 ps-3">
              <span>&#8226;  Heyden Coin launches for trading on a DEX</span>
              <span>&#8226; Heyden Coin goes live on a CEX</span>
              <span>&#8226; Travel portal access for users</span>
              <span>&#8226; Major gaming partnership announcements</span>
              <span>&#8226; Metaverse ideation and initial development</span>
          </div>
        </div>
      </li>
      <li style="--accent-color:#c9953e">
        <div class="date">2025</div>
        <div class="descr px-0 px-md-4 p-4">
          <h3 class="mb-3 fw-semibold">Phase 5</h3>
          <div class="d-flex flex-column gap-3 ps-3">
            <span>&#8226;Collaboration with a gaming studio</span>
            <span>&#8226;Access to multiple metaverses for Heyden Coin NFT holders</span>
            <span>&#8226;Heyden Coin Metaverse goes live</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RoadMapComponent',
}
</script>
<style scoped>
.cst__roadmap {
  --col-gap: 2rem;
  --row-gap: 2rem;
  --line-w: 0.25rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  /* width: min(60rem, 90%); */
  /* margin-inline: auto; */
}

/* line */
.cst__roadmap::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 20;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
.cst__roadmap li:not(:last-child) {
  margin-bottom: var(--row-gap);
}

/* card */
.cst__roadmap li {
  grid-column: 2;
  --inlineP: 1.5rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  grid-template-rows: min-content min-content min-content;
}

/* date */
.cst__roadmap li .date {
  --dateH: 3rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);
  text-align: center;
  background-color: var(--accent-color);
  color: white;
  font-size: 1.25rem;
  font-weight: 700;

  display: grid;
  place-content: center;
  position: relative;

  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* date flap */
.cst__roadmap li .date::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;

  clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}

/* circle */
.cst__roadmap li .date::after {
  content: "";
  position: absolute;
  width: 2rem;
  aspect-ratio: 1;
  background: var(--accent-color);
  border: 0.3rem solid var(--accent-color);
  border-radius: 50%;
  top: 50%;

  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* title descr */
.cst__roadmap li .title,
.cst__roadmap li .descr {
  background: var(--bgColor);
  position: relative;
  padding-inline: 1.5rem;
}

.cst__roadmap li .title {
  overflow: hidden;
  padding-block-start: 1.5rem;
  padding-block-end: 1rem;
  font-weight: 500;
}

.cst__roadmap li .descr {
  padding-block-end: 1.5rem;
  font-weight: 300;
}

/* shadows */
.cst__roadmap li .title::before,
.cst__roadmap li .descr::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  border-radius: 50%;
  filter: blur(4px);
  transform: translate(-50%, 50%);
}

.cst__roadmap li .title::before {
  bottom: calc(100% + 0.125rem);
}

.cst__roadmap li .descr::before {
  z-index: -1;
  bottom: 0.25rem;
}

@media (min-width: 40rem) {
  .cst__roadmap {
    grid-template-columns: 1fr var(--line-w) 1fr;
  }

  .cst__roadmap::before {
    grid-column: 2;
  }

  .cst__roadmap li:nth-child(odd) {
    grid-column: 1;
  }

  .cst__roadmap li:nth-child(even) {
    grid-column: 3;
  }

  /* start second card */
  .cst__roadmap li:nth-child(2) {
    grid-row: 2/4;
  }

  .cst__roadmap li:nth-child(odd) .date::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  .cst__roadmap li:nth-child(odd) .date::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }

  .cst__roadmap li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}

.credits {
  margin-top: 1rem;
  text-align: right;
}

.credits a {
  color: var(--color);
}
</style>

<template>
  <div>
    <section class="game-banner position-relative new-slider">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 px-0">
            <div class="wrapper-new">
              <div class="gaming-content position-absolute h-100 w-100 d-flex flex-column justify-content-center">
                <h1 class="fw-bold">Join the Gaming Revolution Now!</h1>
                <p class="fs-5 mb-4 gaming-text">Experience adrenaline-pumping action, solve mind-bending puzzles, and
                  engage in epic battles with friends in our <br /> exhilarating gaming universe.</p>
                <div class="d-flex align-items-center gap-3 justify-content-start flex-column flex-md-row">
                  <button class="play-btn bgGradient_btn rounded-4 border-0 text-white fw-medium" type="button">Play for
                    Free</button>
                  <button class="play-btn bg-transparent rounded-4 border-0 text-white fw-medium" type="button">Join the
                    community</button>
                </div>
              </div>
              <div class="">
                <video class="w-100 object-fit-cover" preload="auto" autoplay loop playsinline>
                  <source src="../../assets/images/hero.mp4" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="comm-padding top-games">
      <div class="container">
        <div class="row ">
          <div class="col-lg-12">
            <div class="main-title text-center mb-5">
              <h2 class="fw-semibold">Explore top games of Heyden Coin</h2>
              <p class="fs-5 opacity-75">Discover the best of Heyden Coin's gaming universe. Dive into immersive worlds,
                epic quests, and <br /> thrilling challenges. Start exploring now!</p>
            </div>
          </div>
        </div>
        <div class="row gap-0 row-gap-4">
          <div class="col-md-6 col-lg-4 new-Wrapper" v-for="(item, index) in TopGames" :key="index">
            <div class="main-wrapper d-flex flex-column justify-content-between w-100 h-100 rounded-4 py-3 px-4">
              <div class="game-img mb-3">
                <img :src="require('../../assets/images/' + item.image)" alt="" class="img-fluid rounded-4">
              </div>
              <div class="game-content">
                <div>
                  <h4>{{ item.title }}</h4>
                </div>
                <p>{{ item.text }}</p>
                <div class="btn-play mb-3">
                  <button type="button" class=" bgGradient_btn rounded-4 border-0 text-white fw-medium">Play
                    game</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="comm-padding game__slider comm-slider">
      <div class="container-fluid ">
        <div class="row">
          <div class="col-lg-12">
            <div class="Heyden Coin-game d-inline-flex align-items-center mb-4 py-2 px-4 rounded-pill">
              <span>Powered by</span>
              <img src="../../assets/images/star-nest.png" alt="" width="40">
              <h4 class="m-0 fs-6 fw-semibold">Heyden Coin Games</h4>
            </div>
            <div class="game-slider">
              <div class="col-lg-12">
                <carousel :autoplay="1500" :wrap-around="true" :items-to-show="4.20" :snapAlign="snapAlignment"
                  :breakpoints="breakpoints2" :transition="500">
                  <slide v-for="(items, index) in blogSliderData" :key="index">
                    <div class="position-relative" @mouseover="showOverlay(index)" @mouseleave="hideOverlay(index)">
                      <img :src="require('../../assets/images/' + items.image)" alt=""
                        class="w-100 object-fit-cover rounded-4">
                      <div v-if="showOverlayIndex === index"
                        class="wrape position-absolute top-0 start-0 end-0 bottom-0 overflow-hidden z-3 d-flex justify-content-center align-items-center">
                        <transition name="fade">
                          <button type="button" class="text-capitalize bg-white border-0 px-4 rounded-4">View
                            game</button>
                        </transition>
                      </div>
                    </div>
                  </slide>
                  <template #addons>
                    <navigation />
                    <pagination />
                  </template>
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section_overview comm-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="main-title text-center mb-5">
              <h2 class="fw-semibold">Gaming is evolving. Build with Heyden Coin</h2>
              <p class="fs-5 opacity-75">Experience the future of gaming with Heyden Coin. Join us in shaping the next
                generation of <br /> interactive entertainment. Start building now!</p>
            </div>
          </div>
        </div>
        <div class="row gap-0 row-gap-4">
          <div class="col-md-6 col-lg-3" v-for="(items, index) in GameData" :key="index">
            <div class="main-compo">
              <div class="mb-3">
                <img :src="require('../../assets/images/' + items.image)" alt="" width="48">
              </div>
              <h4 class="fw-semibold">{{ items.title }}</h4>
              <p class="fs-6 opacity-75">{{ items.para }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cta-section overflow-hidden">
      <div class="container">
        <div class="row main-cta align-items-center position-relative justify-content-between rounded-5">
          <div class="col-lg-4">
            <div class="cta-back">
              <h2 class="fw-semibold">Let's connect for develop a game!</h2>
              <p>Join our community of developers and creators to collaborate on crafting the next big game. Let's
                connect
                and bring your ideas to life together!</p>
              <button type="button" class="bgGradient_btn rounded-4 border-0 text-white fw-medium">Learn More</button>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="game-main text-center">
              <img src="../../assets/images/game-controller.png" alt="" width="450" class="img-fluid object-fit-cover">
            </div>
            <img src="../../assets/images/cta-bg.webp" alt=""
              class="w-75 object-fit-cover position-absolute top-0 end-0 h-100">
          </div>
        </div>
      </div>
    </section>

    <section class="px-10 pb-5">
      <div class="container">
        <div class="row gap-0 row-gap-4">
          <div class="col-md-6 col-lg-6">
            <div class="video-wrapper h-100">
              <div class="top-tile d-flex flex-column align-items-center mb-5">
                <h2 class="mb-0 section-title text-capitalize">Events </h2>
              </div>
              <div class="events overflow-hidden">
                <div class="newImage-wrap mb-3">
                  <img src="../../assets/images/events.jpg" alt="" width="100%" class="object-fit-cover rounded-4">
                </div>
                <a class="text-decoration-none text-white " href="#">View Event <img src="../../assets/images/arrow.svg"
                    alt=""></a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="video-wrapper h-100">
              <div class="top-tile d-flex flex-column align-items-center mb-5">
                <h2 class="mb-0 section-title text-capitalize">Blogs </h2>
              </div>
              <div class="blog overflow-hidden">
                <div class="newImage-wrap mb-3">
                  <img src="../../assets/images/slider-3.jpg" alt="" width="100%" class="object-fit-cover rounded-4">
                </div>
                <div class="blog-detail">
                  <h3 class="title fs-6 fw-semibold">PIXELMON DEVELOPMENT UPDATE SEPTEMBER</h3>
                  <p>Game Updates • October 3, 2023 </p>
                  <a class="text-decoration-none text-white" href="#">View post <img src="../../assets/images/arrow.svg"
                      alt=""></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
export default {
  name: 'GameView',
  data() {
    return {
      showOverlayIndex: null,
      blogSliderData: [
        {
          image: 'game-slider-1.png',
          title: 'Lorem ipsum dolor sit amet consectetur'
        },
        {
          image: 'game-slider-2.png',
          title: 'Lorem ipsum dolor sit amet consectetur'
        },
        {
          image: 'game-slider-3.png',
          title: 'Lorem ipsum dolor sit amet consectetur'
        },
        {
          image: 'game-slider-4.png',
          title: 'Lorem ipsum dolor sit amet consectetur'
        },
        {
          image: 'slider-1.jpg',
          title: 'Lorem ipsum dolor sit amet consectetur'
        },
        {
          image: 'slider-2.jpg',
          title: 'Lorem ipsum dolor sit amet consectetur'
        },
        {
          image: 'slider-3.jpg',
          title: 'Lorem ipsum dolor sit amet consectetur'
        },
        {
          image: 'slider-4.jpg',
          title: 'Lorem ipsum dolor sit amet consectetur'
        },

      ],
      GameData: [
        {
          image: 'gaming-1.svg',
          title: 'Building made easier',
          para: 'Simplify your gaming creation process with our intuitive tools and resources. Explore endless possibilities and bring your ideas to life effortlessly.'
        },
        {
          image: 'gaming-2.svg',
          title: 'Free transactions',
          para: 'Enjoy seamless transactions with zero fees. Buy, sell, and trade in-game items freely without any hidden charges. Experience true gaming freedom!'
        },
        {
          image: 'gaming-3.svg',
          title: 'Secure and Scalable',
          para: 'Trust our secure infrastructure to handle your gaming needs. Our scalable platform ensures smooth performance, no matter the demand.'
        },
        {
          image: 'gaming-4.svg',
          title: 'Zero knowledge roll-up',
          para: 'Protect your privacy with our zero-knowledge roll-up technology. Enjoy gaming without sacrificing your personal information. Your data stays secure.'
        },

      ],
      TopGames: [
        {
          image: 'explore1.jpg',
          title: 'Battle Brigade',
          text: 'Download the top game on iOS or Android mobile devices.'
        },
        {
          image: 'explore2.png',
          title: 'Midnight Wilds',
          text: 'Download the top game on iOS or Android mobile devices.'
        },
        {
          image: 'explore3.webp',
          title: 'Get The Game',
          text: 'Download the top game on iOS or Android mobile devices.'
        },

      ],
      breakpoints1: {
        0: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        700: {
          itemsToShow: 1.25,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 1.25,
          snapAlign: 'center',
        },
      },
      breakpoints2: {
        0: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        700: {
          itemsToShow: 1.75,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 4.20,
          snapAlign: 'center',
        },
      },

    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    showOverlay(index) {
      this.showOverlayIndex = index;
    },
    hideOverlay() {
      this.showOverlayIndex = null;
    },
  },

}
</script>

<style scoped>
.gaming-content {
  background: linear-gradient(180deg, #000 3.65%, transparent 23.76%),
    linear-gradient(90deg, #050e15 7.06%, transparent 75.49%),
    rgba(211, 211, 211, .2) 0 -12.611px/116.806% 131.998% no-repeat;
  padding: 80px 30px;
}



.game-slider .carousel__slide {
  margin: 0 16px;
}

.game-slider .carousel__slide:first-child {
  margin-left: 0;
}

.wrape {
  background-color: rgba(0, 0, 0, 0.356);
  transition: all 0.3s ease-in-out;
}

.main-wrapper {
  border-color: #374066;
  background: linear-gradient(180deg, #181f36, #141b2f 124.64%);
  -webkit-clip-path: polygon(0 5%, 6% 0, 90% 0, 100% 0, 100% 96%, 96% 100%, 0 100%, 0 90%, 0 10%);
  clip-path: polygon(0 5%, 6% 0, 90% 0, 100% 0, 100% 96%, 96% 100%, 0 100%, 0 90%, 0 10%);
}

.main-wrapper img {
  -webkit-clip-path: polygon(0 5%, 6% 0, 90% 0, 100% 0, 100% 96%, 96% 100%, 0 100%, 0 90%, 0 10%);
  clip-path: polygon(0 5%, 6% 0, 90% 0, 100% 0, 100% 96%, 96% 100%, 0 100%, 0 90%, 0 10%);
}

.cta-back h2 {
  font-size: 48px;
}

.game-main img {
  animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}
</style>
<template>
  <footer class="comm-padding">
    <div class="container-fluid px-15">
      <div class="row justify-content-between">
        <div class="col-lg-2">
            <div class="footer-widget">
               <img src="../assets/artelligence-banner-image-loge.png" alt="" width="150" class="mb-32">
               <div class="footer-wrap">
                  <p class="m-0">© Heyden Coin {{ currentYear }} | All rights reserved.</p>
                  <!-- <p class="m-0"><a class="text-decoration-none text-white" href="#">Terms and Conditions</a> | <a class="text-decoration-none text-white" href="#">Privacy Policy</a></p> -->
               </div>
            </div>
        </div>
        <div class="col-lg-6">
          <div class="footer-widget">
            <h4 class="mb-32">Be the first to know about Heyden Coin.</h4>
            <div class="subs-form d-flex align-items-start gap-3 mb-4 flex-column flex-sm-row align-items-sm-center">
              <input type="text" class="form-control rounded-4 border-1 border-white" placeholder="Enter your Email here..."/>
              <button type="button" class="bgGradient_btn rounded-4 border-0 text-white fw-medium">Submit</button>
            </div>
            <div class="accept-term mb-4 d-flex align-items-center gap-3">
              <input type="checkbox" id="agreecon" class="bg-transparent">
              <label for="agreecon">I agree to receive promotional materials from Heyden Coin. <br>
                  I understand that I can withdraw my consent at any time.</label>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-2">
          <div class="footer-social">
            <h4 class="mb-32">Follow us</h4>
            <ul class="d-flex justify-content-start gap-4 m-0 p-0 flex-wrap">
              <li>
                <a href="https://www.facebook.com/people/Starnest/61556966867896/" target="_blank">
                  <img src="../assets/icons/facebook.svg" alt="facebook">
                </a>
              </li>
             
              <li>
                <a href="https://twitter.com/Starnest_io/" target="_blank">
                  <img src="../assets/icons/Twitter.svg" alt="Twitter">
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/Starnest.io/" target="_blank">
                  <img src="../assets/icons/Instagram.svg" alt="Instagram">
                </a>
              </li>
              <li>
                <a href="https://t.me/Starnest_io" target="_blank">
                  <img src="../assets/icons/telegram.svg" alt="telegram">
                </a>
              </li>

              <li>
                <a href="https://discord.gg/xc3pA6XG84" target="_blank">
                  <img src="../assets/icons/discord.svg" alt="Discord">
                </a>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  props: {
    msg: String
  },
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
}
</script>
<style scoped>
footer{
background: #141414;
}
.footer-wrap p{
font-size: 12px;
}

.px-15{
  padding: 0 8rem;
}

.mb-32{
  margin-bottom: 32px;
}

.subs-form{
  max-width: 580px;
}
.subs-form input{
  height: 50px;
}

.subs-form .form-control:focus {  
    border-color: #7e22ab !important;
    box-shadow: none;
}

.footer-social ul{
  list-style: none;
}

.accept-term input{
  appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    border: 2px solid #fff;
    border-radius: 2px;
    cursor: pointer;
    height: 16px;
    outline: none;
    width: 100%;
    max-width: 15px;
}
.accept-term input[type=checkbox]:checked:before{
    color: #fff;
    content: "✓";
    display: block;
    font-size: 12px;
    position: relative;
    text-align: center;
    top: -3px;
}
</style>

<template>
  <div>
    <div class="main-slider comm-slider">
      <carousel :items-to-show="1.25" :snapAlign="snapAlignment" :breakpoints="breakpoints1" :transition="500"
        :autoplay="2000" :wrap-around="true">
        <slide v-for="(image, index) in mainSlider" :key="index">
          <img :src="require('../../assets/images/' + image)" alt="" class="img-fluid w-100 object-fit-cover rounded-5"
            height="">
        </slide>
        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>

    <section class="experience comm-padding">
      <div class="container-fluid">
        <div class="row gap-0 row-gap-4">
          <div class="col-lg-12 ">
            <div class="main-title text-center pb-2 pb-md-5">
              <h2 class="fw-semibold">Experiences built around the joy of <br> community and ownership</h2>
              <p class="fs-6 opacity-75">Together, we are a community-uplifting powerhouse defining <br> new frontiers
                in Web3 entertainment.</p>
            </div>
          </div>

          <div class="metaverse-column col-sm-6 col-md-6 col-lg-6">
            <div class="metaverse-box h-100 overflow-hidden text-center rounded-4">
              <div class="px-4 pt-4">
                <h2 class="mb-4 text-uppercase fw-semibold">{{ metaverseCol.metaverseColumn.title }}</h2>
                <p class="mb-3">{{ metaverseCol.metaverseColumn.description }}</p>
                <button class="bgGradient_btn border-btn position-relative border-0 rounded-4 z-1 "
                  :data="metaverseCol.metaverseColumn.buttonText" @click="navigateTo('/metaverse')"></button>
              </div>

              <div class="meta-images justify-content-center pt-5 d-flex gap-3">
                <div v-for="(group, groupIndex) in metaverseCol.metaverseColumn.images" :key="groupIndex"
                  class="meta-animate d-flex gap-3  flex-column position-relative bounce-col transition"
                  :class="'group' + groupIndex">
                  <img v-for="(image, imageIndex) in group" :key="imageIndex"
                    :src="require('../../assets/images/' + image)" alt="" width="170" height="170"
                    class="rounded-2 object-fit-cover">
                </div>
              </div>
            </div>
          </div>

          <div class="games-column col-sm-6 col-md-6 col-lg-6">
            <div class="games-box h-100 overflow-hidden text-center rounded-4">
              <div class="px-4 pt-4">
                <h2 class="mb-4 text-uppercase fw-semibold">{{ gameCol.gamesColumn.title }}</h2>
                <p>{{ gameCol.gamesColumn.description1 }}</p>
                <p class="mb-3">{{ gameCol.gamesColumn.description2 }}</p>
                <div class="mt-3">
                  <button class="bgGradient_btn border-btn position-relative border-0 rounded-4 z-1 mt-4"
                    :data="gameCol.gamesColumn.buttonText" @click="navigateTo('/games')"></button>
                </div>
              </div>

              <div class="games-images justify-content-center pt-5 mt-3 pb-4 d-flex gap-3">
                <div class="marque-conatiner d-flex gap-3">
                  <div v-for="(image, index) in gameCol.gamesColumn.images" :key="index" class="transition">
                    <img :src="require('../../assets/images/' + image)" alt="" width="432" height="234"
                      class="object-fit-cover rounded-2">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="virtual-column col-sm-6 col-md-6 col-lg-6">
            <div class="virtual-box h-100 overflow-hidden text-center rounded-4">
              <div class="px-4 pt-4">
                <h2 class="mb-4 text-uppercase fw-semibold">{{ RealEstate.virtualColumn.title }}</h2>
                <p>{{ RealEstate.virtualColumn.description1 }}</p>
                <p class="mb-3">{{ RealEstate.virtualColumn.description2 }}</p>
                <div class="mt-3">
                  <button class="bgGradient_btn border-btn position-relative border-0 rounded-4 z-1 mt-4"
                    :data="RealEstate.virtualColumn.buttonText" @click="navigateTo('/virtual-real-estate')"></button>
                </div>
              </div>

              <div class="virtual-images justify-content-center pt-5 mt-5 pb-4 d-flex gap-3">
                <div class="marque-conatiner d-flex gap-3">
                  <div v-for="(image, index) in RealEstate.virtualColumn.images" :key="index" class="transition">
                    <img :src="require('../../assets/images/' + image)" alt="" width="432" height="234"
                      class="object-fit-cover rounded-2">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="cosmos-column col-sm-6 col-md-6 col-lg-6">
            <div class="cosmos-box h-100 overflow-hidden text-center rounded-4">
              <div class="px-4 pt-4">
                <h2 class="mb-4 text-uppercase text-white fw-semibold">NFT cosmos </h2>
                <p class="mb-3 text-white">Discover unique digital assets join a thriving community, and explore the
                  limitless possibilities of NFT Cosmos.</p>
                <div class="mt-3 d-flex justify-content-center">
                  <!-- <button
                    class="border-0 rounded-4 z-1 mt-4 cosmos-btn d-flex w-100 btn-outline-dark justify-content-center align-items-center">
                    Learn more</button> -->
                  <button class="bgGradient_btn border-btn position-relative border-0 rounded-4 z-1 mt-4"
                    :data="gameCol.gamesColumn.buttonText" @click="navigateTo('/nft-cosmos')"></button>
                </div>
              </div>

              <div class="cosmos-images justify-content-center pt-5 mt-3 pb-4 d-flex gap-3 ">
                <div class="cosmos-game">
                  <img src="../../assets/images/cosmos-game.gif" alt="" class="img-fluid">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 
    <section class="comm-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="d-flex flex-column align-items-center">
              <div class="main-title text-center pb-2 pb-md-5">
                <h2 class="fw-semibold">Proudly Built on</h2>
              </div>
              <div class="partner-card d-grid w-100 gap-4">
               
               
                <div class="partner-Wrap px-5 py-3 rounded-3 text-center">
                  <img src="../../assets/images/nordek.png" alt="" width="100">
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section> -->


    <!-- <section class="proudly">
      <div class="container">
        <div class="row">
          <div class="cl-lg-12">
            <div class="proud-main">
              <h2>Proudly Built on</h2>

              <div class="proudly-wrapper">
                <img src="../../assets/images/nordek.png" alt="" width="100">
              </div>
            </div>

          </div>
        </div>
      </div>
    </section> -->



    <!-- <section class="comm-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="d-flex flex-column align-items-center">
              <div class="main-title text-center pb-2 pb-md-5">
                <h2 class="fw-semibold">Our Partners</h2>
              </div>
              <div class="partner-card d-grid w-100 gap-4">
                <div class="partner-Wrap px-5 py-3 rounded-3 text-center">
                  <img src="../../assets/images/nord.svg" alt="" width="100">
                </div>
                <div class="partner-Wrap px-5 py-3 rounded-3 text-center">
                  <img src="../../assets/images/fastpay.png" alt="" width="100">
                </div>
                <div class="partner-Wrap px-5 py-3 rounded-3 text-center d-flex flex-row justify-content-center gap-2">
                  <img src="../../assets/images/nordex-new.png" alt="" width="120">
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section> -->

    <section class="home-section overflow-hidden position-relative">
      <div class="container-fluid h-100">
        <div class="row New_cta align-items-center position-relative justify-content-between z-1 h-100">
          <div class="col-lg-12">
            <div class="cta-back text-center">
              <h2 class="cta-title text-white fw-semibold mb-4">We're looking to the future</h2>
              <p class="mb-5 fs-6 opacity-75 text-white">Heyden Coin eagerly anticipates the future, exploring
                innovative
                solutions and <br> pioneering new frontiers. With forward-thinking strategies, we're committed to
                shaping
                the <br> world of tomorrow. Join our visionary journey today.</p>
              <div class="ctabtn">
                <a href="https://app.heydencoin.com/login" type="button"
                  class="bgGradient_btn text-decoration-none rounded-4 border-0 text-white fw-medium">Join Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="video-wrap position-absolute top-0 end-0 w-100">
        <video class="" playsinline="" autoplay="" muted="" loop="" width="100%">
          <source src="../../assets/images/bg-info12.mp4" type="video/mp4">
        </video>
      </div>
    </section>

    <section class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="middle_wrap">
              <div class="top-tile d-flex flex-column align-items-center mb-5 text-center main-title">
                <h2 class="section-title text-capitalize fw-semibold">Roadmap</h2>
                <p class="mb-0 fs-6 opacity-75">Our awesome community across the world</p>
              </div>
              <RoadMap />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="comm-padding" id="aboutsection">
      <div class="container">
        <div class="row py-5">
          <div class="col-12 col-lg-6">
            <div class="banner-content-area pr-5">
              <h6 class="fw-light mb-0 text-main">SERVICES WE PROVIDE
              </h6><br>
              <h2 class="fw-semibold cta-title">Eliminate Emotion. <br> Automate Trades with Math and Probability</h2>

              <p class="mb-3 fs-6">
                The Heyden AI Platform is tailored to enhance cryptocurrency trading profits, making it accessible to
                both
                beginners and experts. Our advanced machine learning algorithms provide precise and timely analysis of
                top
                cryptocurrencies, allowing for swift and efficient trading strategies to maximize returns.
              </p>

              <div class="banner-btn d-flex gap-4 mb-3">
                <a href="#"
                  class="text-decoration-none bgGradient_btn rounded-4 border-0 text-white fw-medium px-5">Explore</a>

                <!-- <a href="#" class="create fs-6 fw-lighter">Create</a> -->
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" style="padding-left: 1rem;">
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-6 mt-4" v-for="(slide, index) in emotionWrap" :key="index">
                <div class="video-wrapper h-100">
                  <!-- <video autoplay="" muted width="100%" loop="" playsinline=""  style="border-radius: 12px;">
                                <source :src="require(`../../assets/images/${slide.video}`)" type="video/mp4">
                            </video> -->
                  <!-- <div v-html="slide.video"></div> -->
                  <div class="w-full object-cover rounded-xl profit-section mb-3">
                    <img :src="require(`../../assets/icons/${slide.img}`)" alt="Image description"
                      class="w-full h-full object-cover rounded-md">
                  </div>

                  <h4 class="mb-3">{{ slide.title }}</h4>
                  <p class="m-0">{{ slide.text }}</p>
                </div>

                <!-- <img src="../../assets/icons/profit-3.svg" alt=""> -->

              </div>
            </div>
          </div>


        </div>
      </div>
    </section>




    <section class="wallet-new">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="proud-main">
              <h2>Supported wallet</h2>

              <div class="wallet-btn d-flex flex-row gap-5">
                <div class="wallet-Wrap px-2 px-lg-5 py-3 rounded-3 text-center">
                  <img src="../../assets/images/Metamask.png" alt="" width="200">
                </div>

                <div class="wallet-Wrap px-2 px-lg-5 py-3 rounded-3 text-center">
                  <img src="../../assets/images/TrustWallet.png" alt="" width="200">
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>



    <section class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="middle_wrap">
              <div class="top-tile d-flex flex-column align-items-center mb-5 text-center main-title">
                <h2 class="section-title text-capitalize fw-semibold">FAQ</h2>
                <p class="mb-0 fs-6 opacity-75">Don’t see your question answered?</p>
              </div>
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <FaqView />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="comm-slider blogSlider comm-padding">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="fs-1 mb-4 fw-semibold">Recent News</h3>
            <carousel :items-to-show="4" :snapAlign="snapAlignment" :breakpoints="breakpoints2" :transition="500">
              <slide v-for="(items, index) in blogSliderData" :key="index">
                <div class="position-relative">
                  <img :src="require('../../assets/images/' + items.image)" alt="" class="object-fit-cover rounded-4"
                    width="100%" height="300">
                  <div
                    class="position-absolute blogOverlay w-100 transition top-0 h-100 p-4 text-start d-flex flex-column justify-content-between">
                    <div>
                      <span class="cat-type rounded-2 text-uppercase fw-normal">
                        {{ items.category }}
                      </span>
                    </div>
                    <div>
                      <h4 class="mb-2">{{ items.title }} </h4>
                      <div class="d-flex align-items-center justify-content-between"> </div>
                    </div>
                  </div>
                </div>
              </slide>
              <template #addons>
                <navigation />
                <pagination />
              </template>
            </carousel>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import RoadMap from '../../components/RoadMap.vue';
import FaqView from '../../components/FaqView.vue';
export default {
  name: 'App',
  data() {
    return {
      mainSlider: [
        'real-estate-3.jpg',
        'slider-2.jpg',
        'slider-3.jpg',
        'futuristic-sp.jpg',
      ],
      tableData: [
        {
          "id": 1,
          "name": "John",
          "wallet": "0x8cd3...c002",
          "owned": 5,
          "percentageOwned": "0.5%"
        },
        {
          "id": 2,
          "name": "Alice",
          "wallet": "0x7ab2...f109",
          "owned": 8,
          "percentageOwned": "0.8%"
        },
        {
          "id": 3,
          "name": "Bob",
          "wallet": "0x5e9d...d76c",
          "owned": 12,
          "percentageOwned": "1.2%"
        },
        {
          "id": 4,
          "name": "Emily",
          "wallet": "0x4f8e...b2c1",
          "owned": 15,
          "percentageOwned": "1.5%"
        },
        {
          "id": 5,
          "name": "Michael",
          "wallet": "0x3d1a...a804",
          "owned": 20,
          "percentageOwned": "2.0%"
        },
        {
          "id": 6,
          "name": "Sophia",
          "wallet": "0x2c7b...c903",
          "owned": 25,
          "percentageOwned": "2.5%"
        },
        {
          "id": 7,
          "name": "David",
          "wallet": "0x1b5e...e713",
          "owned": 18,
          "percentageOwned": "1.8%"
        },
        {
          "id": 8,
          "name": "Emma",
          "wallet": "0xa2d9...d609",
          "owned": 30,
          "percentageOwned": "3.0%"
        },
        {
          "id": 9,
          "name": "Daniel",
          "wallet": "0x9c4f...f217",
          "owned": 22,
          "percentageOwned": "2.2%"
        },
        {
          "id": 10,
          "name": "Olivia",
          "wallet": "0x8b1a...a905",
          "owned": 17,
          "percentageOwned": "1.7%"
        }
      ],
      breakpoints1: {
        0: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        700: {
          itemsToShow: 1.25,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 1.25,
          snapAlign: 'center',
        },
      },
      breakpoints2: {
        0: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        700: {
          itemsToShow: 1.25,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 3.70,
          snapAlign: 'center',
        },
      },
      blogSliderData: [
        {
          image: 'blog-metaverse.jpg',
          category: 'NFT',
          title: 'Unveiling the Cosmos: Embark on the Heyden Coin Interstellar NFT Journey',
          // user: 'author',
          // date: '05 Oct 2003',

        },
        {
          image: 'blog-game.jpg',
          category: 'Metaverse',
          title: 'Step into a World of Adventure: Join the Ultimate Heyden Coin Metaverse Today!',
          user: 'author',
          date: '22 Jan 2024',
        },
        {
          image: 'blog-cosmos.jpg',
          category: 'Games',
          title: 'Embark on Epic Journeys: Explore the Heyden Coin Gaming Universe',
          user: 'author',
          date: '15 Mar 2003',
        },
        {
          image: 'blog-nft.jpg',
          category: 'NFT Cosmos',
          title: 'Forge Alliances, Conquer Realms: The Ultimate Metaverse Gaming Odyssey',
          user: 'author',
          date: '05 Oct 2003',
        },
      ],
      emotionWrap: [
        {
          img: 'profit.svg',
          title: 'Risk Management',
          text: "Mitigate risks in challenging markets using customizable stop loss signals"
        },
        {
          img: 'profit-1.svg',
          title: 'Robotic Automation',
          text: "Efficiency and precision achieved through transformative"
        },
        {
          img: 'profit-2.svg',
          title: 'Trade Spot and Futures',
          text: 'Offers powerful features for trading spot and futures markets'
        },
        {
          img: 'profit-3.svg',
          title: 'Take profit targets',
          text: 'Automated trading bot tracks market for optimal take profit targets'
        }
      ],
      gameCol: {
        "gamesColumn": {
          "title": "Games",
          "description1": "",
          "description2": "Experience excitement Strategy, and thrills with our wide range of immersive games for all players.",
          "buttonText": "Learn More",
          "images": [
            "game-img-1.jpg",
            "game-img-2.jpg",
            "game-img-3.jpg",
            "game-img-4.jpg",
            "game-img-5.jpg"
          ]
        }
      },
      metaverseCol: {
        "metaverseColumn": {
          "title": "metaverse",
          "description": "Discover, connect, and create in a boundless digital universe - welcome to the immersive Metaverse experience.",
          "buttonText": "Learn More",
          "images": [
            ["meta.jpg", "meta1.jpg"],
            ["meta2.jpg", "meta3.jpg"],
            ["meta4.jpg", "meta5.jpg"],
            ["meta6.jpg", "meta7.jpg"],
            ["meta8.jpg", "meta9.jpg"]
          ]
        }
      },
      RealEstate: {
        "virtualColumn": {
          "title": "Virtual real estate",
          "description1": "",
          "description2": "Unlock prime virtual properties Build dreams, and invest in the future with our virtual real estate platform",
          "buttonText": "Learn More",
          "images": [
            "real-estate-1.jpg",
            "real-estate-2.jpg",
            "real-estate-3.jpg",
            "real-estate-4.jpg",
            "real-estate-5.jpg"
          ]
        }
      }

    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    RoadMap,
    FaqView
  },
  methods: {
    navigateTo(loc) {
      this.$router.push(loc)
    },

  },
}
</script>

<style scoped>
.main-slider img {
  height: 650px !important;
  mix-blend-mode: overlay;
  padding: 0 12px;
}

.main-slider .carousel__slide {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0) 50%);
  padding: 10px 0 40px;
}

.border-btn::before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background-color: #0000;
  z-index: -1;
  border-radius: 16px;
}

.border-btn::after {
  content: attr(data);
  background-image: linear-gradient(170deg, #d846a5, #4d0eae);
  -webkit-background-clip: text;
  color: #FFF;
}


.metaverse-box {
  background-image: radial-gradient(120% 180% at 50% 200%, rgb(187 143 53) 0, rgba(255, 0, 165, 0) 100%), radial-gradient(90% 50% at 50% 130%, rgb(224 162 38) 0, rgba(255, 0, 165, 0) 100%);
  background-color: rgb(20 20 20/ 1);
}

.bounce-col {
  animation: bounceCol 3s cubic-bezier(.4, 0, .2, 1) infinite alternate;
}

.transition {
  transition-duration: .15s;
  transition-property: color, background-color, border-color, text-decoration-color,
    fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.group0 {
  top: 128px;
  animation-delay: .1s;
}

.group1 {
  top: 83px;
  animation-delay: .3s;
}

.group2 {
  top: 51px;
  animation-delay: .5s;
}

.group4 {
  top: 28px;
  animation-delay: 1s;
}

@keyframes bounceCol {
  0% {
    transform: translateZ(0);
  }

  100% {
    transform: translate3d(0, 20%, 0);
  }
}


.games-box {
  background-image: radial-gradient(120% 180% at 50% 200%, rgb(178 174 86) 0, rgba(0, 132, 248, 0) 100%), radial-gradient(90% 50% at 50% 130%, rgb(0 0 0) 0, rgba(0, 132, 248, 0) 100%);
  background-color: rgb(20 20 20/ 1)
}

.marque-conatiner>div {
  animation: marquee 20s linear infinite;
  animation-play-state: running;
}

@keyframes marquee {
  0% {
    transform: translateZ(0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}


.virtual-box {
  background-image: radial-gradient(120% 180% at 50% 200%, rgb(189 144 53) 0, rgba(0, 132, 248, 0) 100%), radial-gradient(90% 50% at 50% 130%, rgb(229 173 59) 0, rgba(0, 132, 248, 0) 100%);
  background-color: rgb(20 20 20/ 1)
}


.cosmos-box {

  background-image: radial-gradient(120% 180% at 50% 200%, rgb(229 174 60) 0, rgba(255, 0, 165, 0) 100%), radial-gradient(90% 50% at 50% 130%, rgb(103 75 44) 0, rgba(255, 0, 165, 0) 100%);
  background-color: rgb(20 20 20 / 1);


}

.cosmos-btn {
  height: 48px;
  max-width: 147px;
}

/* .sectionCta {
    background: linear-gradient(0deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 91%)), url('../../assets/images/home-cta.jpg') no-repeat 100%;
    background-size: cover;
    height: 100vh;
    position: relative;
  } */

/* .sectionCta::before {
    content: '';
    background-image: linear-gradient(0deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 91%));
    width: 100%;
    position: absolute;
    height: 40px;
    top: 0;
  }

  .sectionCta::after {
    content: '';
    background-image: linear-gradient(0deg, rgb(0 0 0 / 91%), rgb(0 0 0 / 0%));
    width: 100%;
    position: absolute;
    height: 40px;
    bottom: 0;
  } */

.cta-title {
  font-size: 64px;
  line-height: 66px;
}

/* blog */
.blogOverlay {
  background: linear-gradient(to bottom, rgb(0 0 0 / 33%) 0, #00000029 100%);
  cursor: pointer;
}

.blogOverlay:hover {
  background: linear-gradient(to bottom, rgba(0, 0, 0, .3) 0, #00000082 100%);
}

.blogSlider .carousel__slide {
  margin: 0 16px;
}

.blogSlider .carousel__slide:first-child {
  margin-left: 0;
}

.cat-type {
  background-color: #e1e1e191;
  padding: 6px 10px;
  font-size: 12px;
}

.home-section {
  height: 700px;
}

.banner-content-area h1 {
  font-size: 71px;
  font-weight: 800;
}

.fs-50 {
  font-size: 50px;
  font-weight: 800;
}

.banner-content-area h1 span {
  color: #f3a735;
}

/* .community-tbale {
    border: 1px solid hsla(0, 0%, 100%, .2);
  }

  .community-tbale .New_table {
    height: 400px;
    overflow-y: scroll;
  }

  .community-tbale .table>:not(caption)>*>* {
    background-color: #0000;
    color: #FFF;
    padding: 16px;
  } */

.New-tabs .nav-tabs .nav-item.show .nav-link,
.New-tabs .nav-tabs .nav-link.active {
  color: #FFF;
  background-color: #0000;
  border-color: #FFF;
}

.New-tabs .nav-tabs .nav-link:hover {
  border-color: hsla(0, 0%, 100%, .2);
}

.New-tabs .nav-tabs .nav-link {
  color: #858585;
  font-size: 14px;
}

.new_chart img.logo {
  position: absolute;
  width: 80px;
  top: 43%;
  left: 51%;
  transform: translate(-43%, -51%);
  animation: spin 5s linear infinite;
}

:is(.New-tabs ul li, .New-tabs p) {
  color: #afafaf;
  line-height: 30px;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.partner-card {
  grid-template-columns: repeat(auto-fill, minmax(410px, 1fr));
}

.partner-Wrap {
  background-color: hsla(0, 0%, 100%, .09);
  border: 1px solid hsla(0, 0%, 100%, .15);
  height: 60px;
  transition: all 0.3s ease-in-out;
}

.partner-Wrap:hover {
  background-color: #ffffff42;
}

.partner-Wrap p {
  font-size: 16px;
  font-weight: 600;
  font-family: inherit;

}

.proudly-wrapper {
  background-color: hsla(0, 0%, 100%, .09);
  border: 1px solid hsla(0, 0%, 100%, .15);
  height: 60px;
  transition: all 0.3s ease-in-out;
  width: 400px;
  border-radius: 7px;
  padding: 16px 136px;
}

.proudly-wrapper:hover {
  background-color: #ffffff42;
}

.proud-main h2 {
  font-size: 48px;
  padding-bottom: 3rem !important;
  font-weight: 600;
}

.proud-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 320px) and (max-width: 575px) {
  .wallet-btn {
    flex-direction: column !important;
  }
}

@media screen and (max-width:768px) {
  .partner-card {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .cta-title {
    font-size: 42px;
    line-height: 48px;
  }

  .proud-main h2 {
    font-size: 26px;
  }

  .proudly-wrapper {
    max-width: 293px;
    padding: 15px 95px;
  }

  .proud-main h2 {
    padding-bottom: 1rem !important;
  }


}

@media (min-width:768px) and (max-width:1200px) {
  .partner-card {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .proudly-wrapper {
    padding: 16px 140px;
  }


}


.wallet-Wrap {
  background-color: hsla(0, 0%, 100%, .09);
  border: 1px solid hsla(0, 0%, 100%, .15);
  /* height: 60px; */
  transition: all 0.3s ease-in-out;
}

.profit-section {
  height: 80px;
  width: 80px;
  background-color: #8c874436;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.video-wrapper {
  transition: all 0.3s ease;

}

.video-wrapper:hover {
  transform: translateY(10px);
}
</style>
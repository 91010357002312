<template>
  <div>
    <Header />

    <router-view></router-view>

    <Footer />
  </div>
</template>


<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  data() {
    return {

    }
  },
  components: {
    Header,
    Footer,
  },
}
</script>

<style>
@font-face {
  font-family: "Figtree", sans-serif;
  src: url('../src/assets/fonts/Figtree-Black.ttf');
  font-weight: 300;
}

@font-face {
  font-family: "Figtree", sans-serif;
  src: url('../src/assets/fonts/Figtree-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: "Figtree", sans-serif;
  src: url('../src/assets/fonts/Figtree-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: "Figtree", sans-serif;
  src: url('../src/assets/fonts/Figtree-Light.ttf');
  font-weight: 200;
}

@font-face {
  font-family: "Figtree", sans-serif;
  src: url('../src/assets/fonts/Figtree-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: "Figtree", sans-serif;
  src: url('../src/assets/fonts/Figtree-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: "Figtree", sans-serif;
  src: url('../src/assets/fonts/Figtree-SemiBold.ttf');
  font-weight: 600;
}
a{
  text-decoration: none !important;
}
::selection {
  color: #000;
  background: #daa131;
}
html body {
  font-family: "Figtree", sans-serif;
  background-color: #000;
  color: #FFF;
  cursor: url(./assets/images/cursor.svg), default;
}
::-webkit-scrollbar {
  width: 3px;
  position: relative;
}
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar-thumb {
  background: #f2e6e0;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: #1d1d1d;
}
.comm-padding {
  padding: 80px 0;
}
button {
  height: 50px;
}
.px-10 {
  padding: 0 137px !important;
}
.h-650 {
  height: 650px;
}
.fs-7 {
  font-size: 14px;
}  
.new-slider h1 {
  font-size: 64px;
}
/* Button CSS */
.bgGradient_btn {
  background-image: linear-gradient(264.28deg, #eecd53 -38.2%, #e4aa39 103.12%);
  padding: 12px 35px;
  transition: all 0.5s ease-in-out;
  min-height: 50px;
}

.bgGradient_btn:hover {
  background-image: linear-gradient(360deg, #e4aa39, #eecd53);
}

.Text__gradient {
  background-image: linear-gradient(170deg, #eecd53, #e4aa39);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}



.comm-slider button.carousel__prev,
.comm-slider button.carousel__next {
  background: linear-gradient(264.28deg, #e4aa39 -38.2%, #eecd53 103.12%);
  border-radius: 50%;
}

.comm-slider button.carousel__prev svg,
.comm-slider button.carousel__next svg {
  fill: #FFF;
}

.comm-slider .carousel__pagination-button::after {
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #9b9b9b;
}

.comm-slider .carousel__pagination-button:hover::after,
.comm-slider .carousel__pagination-button--active::after {
  background: linear-gradient(264.28deg, #e4aa39 -38.2%, #eecd53 103.12%);
}



.meta-slider .carousel__pagination,
.game-slider .carousel__pagination,
.blogSlider .carousel__pagination {
  display: none;
}

.main-title h2 {
  font-size: 48px;
}

/* 
body .main-slide .carousel__pagination-button::after {
  content: '';
  width: 30px;
  height: 4px;
  border-radius: 4px;
} */

.game-slider .carousel__next,
.blogSlider .carousel__next {
  top: -46px;
  right: -2px;
}

.game-slider .carousel__prev,
.blogSlider .carousel__prev {
  top: -46px;
  right: 42px !important;
  left: unset;
}

.main-slide .carousel__track {
  margin: 0;
}

.video-wrapper {
  border-radius: 12px;
  border: 1px solid hsla(0, 0%, 100%, .15);
  background: hsla(0, 0%, 100%, .09);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 24px;
  position: relative;
}


section.cta-section {
 background-image: linear-gradient(rgba(96, 85, 169, 0), #744c00 30%, #7a5100 68%, rgba(96, 85, 169, 0));
  padding: 100px 137px;
}

.main-cta {
  background-color: #12111c99;
  padding: 80px 30px;
}

.New_cta {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0) 50%);
}


.real-slider .carousel__next {
  left: 5%;
  top: 105%;
}

.real-slider .carousel__prev {
  left: 2%;
  top: 105%;
}

@media screen and (max-width:767px) {
  .navbar-brand img {
    width: 130px;
  }

  .px-15 {
    padding: 0 1rem !important;
  }

  .px-10 {
    padding: 0 1rem !important;
  }

  .main-title h2 {
    font-size: 26px;
  }

  body .gaming-content {
    position: unset !important;
    padding: 10px !important;
    text-align: center;
  }

  .new-slider h1 {
    font-size: 38px;
  }

  body .gaming-text {
    font-size: 16px !important;
  }

  .game-slider .carousel__next,
  .blogSlider .carousel__next {
    top: 50%;
    right: 0;
  }

  .game-slider .carousel__prev,
  .blogSlider .carousel__prev {
    top: 50%;
    left: 0;
  }


  .comm-padding {
    padding: 20px 0;
  }

  section.cta-section {
    padding: 20px;
  }

  .cta-back h2 {
    font-size: 26px !important;
  }

  .main-cta {
    padding: 43px 15px;
    text-align: center;
    gap: 10px;
  }

  .metOverlay{
    left: 0 !important;
  }

  .main-title{
    text-align: center;
  }


.featured-section .content{
  text-align: center;
}

.cta-title {
    font-size: 33px;
    line-height: 35px;
}

.home-section{
    height: 500px;
}

.divider-line {
    margin: 30px !important;
}

body p {
    font-size: 16px !important;
}

}

@media (min-width:768px) and (max-width:991px) {
  .new-slider h1 {
    font-size: 50px;
  }

  .main-title h2 {
    font-size: 36px;
  }

  .comm-padding {
    padding: 40px 0;
  }

  section.cta-section {
   background-image: linear-gradient(rgba(96, 85, 169, 0), #744c00 30%, #7a5100 68%, rgba(96, 85, 169, 0));
    padding: 60px;
  }

  .featured-section .content{
  text-align: center;
}
}

.tokenomics .apexcharts-legend.apx-legend-position-right {
    justify-content: center;
    gap: 6px;
}

.tokenomics .apexcharts-legend-series .apexcharts-legend-text{
    color: #FFF !important;
}
.text-main{
  color: #e4aa39 !important;
}
@media screen and (max-width:1200px){
  body .nft_padding {
  padding: 30px 0 ;
}
}

</style>
<template>
  <div>
    <div class="nft-banner nft_padding">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="banner-content-area">
              <h1 class="fw-light mb-3">
                <span>Create</span>, Collect And <span>Sell</span> Digital
                Items.
              </h1>

              <p class="mb-3 fs-6">
                Digital Marketplace For Crypto Collectibles And Non-Fungible
                Tokens. Buy, Sell, And Discover Exclusive Digital Assets.
              </p>

              <div class="banner-btn d-flex gap-4 mb-3">
                <a href="#"
                  class="text-decoration-none bgGradient_btn rounded-4 border-0 text-white fw-medium px-5">Explore</a>

                <!-- <a href="#" class="create fs-6 fw-lighter">Create</a> -->
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <swiper :slides-per-view="1" :space-between="50" @swiper="onSwiper" @slideChange="onSlideChange"
              class="hhhhhhh">
              <swiper-slide>
                <div class="swipper-inner">
                  <div class="swipper-top d-flex justify-content-between align-items-center">
                    <div class="swipper-logo d-flex align-items-center gap-3">
                      <img src="../../assets/images/nft-img/nft-top-img.png" alt="" />
                      <h6 class="fs-5 mb-0">
                        <a href="#">Matilda </a>
                      </h6>
                    </div>

                    <div class="nft-dots">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots"
                        viewBox="0 0 16 16">
                        <path
                          d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                      </svg>
                    </div>
                  </div>

                  <div class="nft-card-img">
                    <img src="../../assets/images/banner-img-1.jpg" class="img-fluid" />
                  </div>

                  <div class="nft-card-footer">
                    <h4 class="mt-2">Future Rocket</h4>

                    <div class="nft-footer-price d-flex justify-content-between">
                      <p class="fs-4">
                        Price
                        <span>2.55 SNT</span>
                      </p>

                      <a href="#" class="fs-5 text-decoration-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                          class="bi bi-suit-heart-fill" viewBox="0 0 16 16">
                          <path
                            d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1" />
                        </svg>
                        230
                      </a>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="swipper-inner">
                  <div class="swipper-top d-flex justify-content-between align-items-center">
                    <div class="swipper-logo d-flex align-items-center gap-3">
                      <img src="../../assets/images/nft-img/nft-top-2-cat.png" alt="" />
                      <h6 class="fs-5 mb-0">
                        <a href="#">Jack </a>
                      </h6>
                    </div>

                    <div class="nft-dots">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots"
                        viewBox="0 0 16 16">
                        <path
                          d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                      </svg>
                    </div>
                  </div>

                  <div class="nft-card-img">
                    <img src="../../assets/images/banner-img-2.jpg" class="img-fluid" />
                  </div>

                  <div class="nft-card-footer">
                    <h4 class="mt-3">Silly C4T de</h4>

                    <div class="nft-footer-price d-flex justify-content-between">
                      <p class="fs-4">
                        Price
                        <span>4.62 SNT </span>
                      </p>

                      <a href="#" class="fs-5 text-decoration-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                          class="bi bi-suit-heart-fill" viewBox="0 0 16 16">
                          <path
                            d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1" />
                        </svg>
                        230
                      </a>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="swiper-element">
                  <div class="swipper-wrapper">
                    <div class="swipper-inner">
                      <div class="swipper-top d-flex justify-content-between align-items-center">
                        <div class="swipper-logo d-flex align-items-center gap-3">
                          <img src="../../assets/images/nft-img/nft-top-2-cat.png" alt="" />
                          <h6 class="fs-5 mb-0">
                            <a href="#">Oliver</a>
                          </h6>
                        </div>

                        <div class="nft-dots">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots"
                            viewBox="0 0 16 16">
                            <path
                              d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                          </svg>
                        </div>
                      </div>

                      <div class="nft-card-img">
                        <img src="../../assets/images/banner-img-3.jpg" class="img-fluid" />
                      </div>

                      <div class="nft-card-footer">
                        <h4 class="mt-3">Black Cat</h4>

                        <div class="nft-footer-price d-flex justify-content-between">
                          <p class="fs-4">
                            Price
                            <span>5.34 SNT </span>
                          </p>

                          <a href="#" class="fs-5 text-decoration-none">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                              class="bi bi-suit-heart-fill" viewBox="0 0 16 16">
                              <path
                                d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1" />
                            </svg>
                            230
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="swiper-element">
                  <div class="swipper-wrapper">
                    <div class="swipper-inner">
                      <div class="swipper-top d-flex justify-content-between align-items-center">
                        <div class="swipper-logo d-flex align-items-center gap-3">
                          <img src="../../assets/images/nft-img/nft-top-2-cat.png" alt="" />
                          <h6 class="fs-5 mb-0">
                            <a href="#">Noah</a>
                          </h6>
                        </div>

                        <div class="nft-dots">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots"
                            viewBox="0 0 16 16">
                            <path
                              d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                          </svg>
                        </div>
                      </div>

                      <div class="nft-card-img">
                        <img src="../../assets/images/banner-img-4.jpg" class="img-fluid" />
                      </div>

                      <div class="nft-card-footer">
                        <h4 class="mt-3">EUPHORIA de</h4>

                        <div class="nft-footer-price d-flex justify-content-between">
                          <p class="fs-4">
                            Price
                            <span>7.00 SNT</span>
                          </p>

                          <a href="#" class="fs-5 text-decoration-none">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                              class="bi bi-suit-heart-fill" viewBox="0 0 16 16">
                              <path
                                d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1" />
                            </svg>
                            230
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>

    <section class="wallet-section nft_padding">
      <div class="container">
        <div class="wallet-heading d-flex align-items-center justify-content-center mb-3">
          <h3 class="fs-1">ALL WALLET OPTIONS</h3>
        </div>
        <div class="row gap-2">
          <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2" v-for="(value, index) in walletoption" :key="index">
            <div class="wallet-box d-flex flex-column justify-content-center align-items-center mb-4">
              <img :src="require(`../../assets/images/nft-img/${value.img}`)" class="img-fluid" />

              <h3 class="mt-2">{{ value.para }}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="auction-section">
      <div class="container">
        <div class="wallet-heading d-flex align-items-center justify-content-center mb-3">
          <h3 class="fs-1">ALL WALLET OPTIONS</h3>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <carousel :items-to-show="4" :snapAlign="snapAlignment" :breakpoints="breakpoints2" :transition="500">
              <slide v-for="(option, index) in AuctionSlider" :key="index">
                <div class="swipper-inner-2">
                  <div class="swipper-top d-flex justify-content-between align-items-center">
                    <div class="swipper-logo d-flex align-items-center gap-3">
                      <img :src="require('../../assets/images/nft-img/' + option.img)
                        " :alt="option.img" />
                      <h6 class="fs-5 mb-0">
                        <a :href="option.link">{{ option.name }}</a>
                      </h6>
                    </div>

                    <div class="nft-dots">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots"
                        viewBox="0 0 16 16">
                        <path
                          d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                      </svg>
                    </div>
                  </div>

                  <div class="nft-card-img text-center">
                    <img :src="require('../../assets/images/' + option.image)" class="img-fluid" />

                    <div class="nft-countdown">
                      <ul class="count-down-class d-flex flex-row list-unstyled justify-content-around">
                        <li>
                          <span class="days">0</span>
                          <span class="count-txt">D</span>
                        </li>

                        <li>
                          <span class="days">0</span>
                          <span class="count-txt">H</span>
                        </li>

                        <li>
                          <span class="days">0</span>
                          <span class="count-txt">M</span>
                        </li>

                        <li>
                          <span class="days">0</span>
                          <span class="count-txt">S</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="nft-card-footer-2">
                    <h4 class="mt-2">{{ option.title }}</h4>

                    <div class="nft-footer-price d-flex justify-content-between">
                      <p class="fs-6">
                        Price
                        <span>{{ option.price }}</span>
                      </p>

                      <a :href="option.likeLink" class="fs-5 text-decoration-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                          class="bi bi-suit-heart-fill" viewBox="0 0 16 16">
                          <path
                            d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1" />
                        </svg>
                        {{ option.likes }}
                      </a>
                    </div>
                  </div>
                </div>
              </slide>
              <template #addons>
                <navigation />
                <pagination />
              </template>
            </carousel>
          </div>
        </div>
      </div>
    </section>

    <section class="browse-category nft_padding">
      <div class="container">
        <div class="wallet-heading d-flex align-items-center justify-content-center mb-3">
          <h3 class="fs-1">BROWSE BY CATERGORY</h3>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="browser-category-wrapper mb-5">
              <carousel :items-to-show="1" :snapAlign="snapAlignment" :breakpoints="breakpoints3" :transition="500">
                <slide v-for="(browse, index) in categorySlider" :key="index">
                  <div class="browser-slider-wrapper">
                    <div class="browse-swiper d-flex align-items-center gap-3">
                      <img :src="require('../../assets/images/' + browse.img)" :alt="browse.img" height="300"
                        width="400" />
                    </div>
                  </div>
                </slide>
              </carousel>

              <div class="browse-content">
                <h4 class="mb-0">Digital Art</h4>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="browser-category-wrapper mb-5">
              <carousel :items-to-show="1" :snapAlign="snapAlignment" :breakpoints="breakpoints3" :transition="500">
                <slide v-for="(browse, index) in categorySlider2" :key="index">
                  <div class="browser-slider-wrapper">
                    <div class="browse-swiper d-flex align-items-center gap-3">
                      <img :src="require('../../assets/images/' + browse.img)" :alt="browse.img" height="300"
                        width="400" />
                    </div>
                  </div>
                </slide>
              </carousel>

              <div class="browse-content">
                <h4 class="mb-0">Music</h4>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="browser-category-wrapper mb-5">
              <carousel :items-to-show="1" :snapAlign="snapAlignment" :breakpoints="breakpoints3" :transition="500">
                <slide v-for="(browse, index) in categorySlider3" :key="index">
                  <div class="browser-slider-wrapper">
                    <div class="browse-swiper d-flex align-items-center gap-3">
                      <img :src="require('../../assets/images/' + browse.img)" :alt="browse.img" height="300"
                        width="400" />
                    </div>
                  </div>
                </slide>
              </carousel>

              <div class="browse-content">
                <h4 class="mb-0">Domain Name</h4>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="browser-category-wrapper mb-5">
              <carousel :items-to-show="1" :snapAlign="snapAlignment" :breakpoints="breakpoints3" :transition="500">
                <slide v-for="(browse, index) in categorySlider4" :key="index">
                  <div class="browser-slider-wrapper">
                    <div class="browse-swiper d-flex align-items-center gap-3">
                      <img :src="require('../../assets/images/' + browse.img)" :alt="browse.img" height="300"
                        width="400" />
                    </div>
                  </div>
                </slide>
              </carousel>

              <div class="browse-content">
                <h4 class="mb-0">Sports</h4>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="browser-category-wrapper mb-5">
              <carousel :items-to-show="1" :snapAlign="snapAlignment" :breakpoints="breakpoints3" :transition="500">
                <slide v-for="(browse, index) in categorySlider5" :key="index">
                  <div class="browser-slider-wrapper">
                    <div class="browse-swiper d-flex align-items-center gap-3">
                      <img :src="require('../../assets/images/' + browse.img)" :alt="browse.img" height="300"
                        width="400" />
                    </div>
                  </div>
                </slide>
              </carousel>

              <div class="browse-content">
                <h4 class="mb-0">Utilities</h4>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="browser-category-wrapper mb-5">
              <carousel :items-to-show="1" :snapAlign="snapAlignment" :breakpoints="breakpoints3" :transition="500">
                <slide v-for="(browse, index) in categorySlider6" :key="index">
                  <div class="browser-slider-wrapper">
                    <div class="browse-swiper d-flex align-items-center gap-3">
                      <img :src="require('../../assets/images/' + browse.img)" :alt="browse.img" height="300"
                        width="400" />
                    </div>
                  </div>
                </slide>
              </carousel>

              <div class="browse-content">
                <h4 class="mb-0">All NFT's</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="top-seller-main nft_padding">
      <div class="container">
        <div class="wallet-heading d-flex align-items-center justify-content-center mb-3">
          <h3 class="fs-1">TOP SELLERS</h3>
        </div>

        <div class="row justify-content-center">
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="top-seller-wrapper mb-4">
              <div class="top-seller-img-wrapper text-center">
                <div class="top-seller-img">
                  <img src="../../assets/images/seller-img-1.jpg" class="w-100 toop-s" />
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="top-seller-inner-img" />
                </div>
              </div>

              <div class="top-seller-content d-flex justify-content-between">
                <div class="tsc mt-4">
                  <h3 class="fs-1">01</h3>
                </div>

                <div class="tsc-details d-flex justify-content-center flex-column">
                  <a href="#" class="text-decoration-none">CryptoPunks</a>
                  <p class="tsc-details-proce mb-0">90.00 SNT</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="top-seller-wrapper mb-4">
              <div class="top-seller-img-wrapper text-center">
                <div class="top-seller-img">
                  <img src="../../assets/images/seller-img-2.jpg" class="w-100 toop-s" />
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="top-seller-inner-img" />
                </div>
              </div>

              <div class="top-seller-content d-flex justify-content-between">
                <div class="tsc mt-4">
                  <h3 class="fs-1">02</h3>
                </div>

                <div class="tsc-details d-flex justify-content-center flex-column">
                  <a href="#" class="text-decoration-none">Decentraland </a>
                  <p class="tsc-details-proce mb-0">25.5 SNT</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="top-seller-wrapper mb-4">
              <div class="top-seller-img-wrapper text-center">
                <div class="top-seller-img">
                  <img src="../../assets/images/seller-img-3.jpg" class="w-100 toop-s" />
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="top-seller-inner-img" />
                </div>
              </div>

              <div class="top-seller-content d-flex justify-content-between">
                <div class="tsc mt-4">
                  <h3 class="fs-1">03</h3>
                </div>

                <div class="tsc-details d-flex justify-content-center flex-column">
                  <a href="#" class="text-decoration-none">Art Blocks</a>
                  <p class="tsc-details-proce mb-0">100.00 SNT</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="top-seller-wrapper mb-4">
              <div class="top-seller-img-wrapper text-center">
                <div class="top-seller-img">
                  <img src="../../assets/images/seller-img-4.jpg" class="w-100  toop-s" />
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="top-seller-inner-img" />
                </div>
              </div>

              <div class="top-seller-content d-flex justify-content-between">
                <div class="tsc mt-4">
                  <h3 class="fs-1">04</h3>
                </div>

                <div class="tsc-details d-flex justify-content-center flex-column">
                  <a href="#" class="text-decoration-none">BAKC</a>
                  <p class="tsc-details-proce mb-0">50.00 SNT</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="top-seller-wrapper mb-4">
              <div class="top-seller-img-wrapper text-center">
                <div class="top-seller-img">
                  <img src="../../assets/images/seller-img-5.jpg" class="w-100 toop-s" />
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="top-seller-inner-img" />
                </div>
              </div>

              <div class="top-seller-content d-flex justify-content-between">
                <div class="tsc mt-4">
                  <h3 class="fs-1">05</h3>
                </div>

                <div class="tsc-details d-flex justify-content-center flex-column">
                  <a href="#" class="text-decoration-none">Pudgy Penguins</a>
                  <p class="tsc-details-proce mb-0">72.00 SNT</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="top-seller-wrapper mb-4">
              <div class="top-seller-img-wrapper text-center">
                <div class="top-seller-img">
                  <img src="../../assets/images/seller-img-6.jpg" class="w-100  toop-s" />
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="top-seller-inner-img" />
                </div>
              </div>

              <div class="top-seller-content d-flex justify-content-between">
                <div class="tsc mt-4">
                  <h3 class="fs-1">06</h3>
                </div>

                <div class="tsc-details d-flex justify-content-center flex-column">
                  <a href="#" class="text-decoration-none">Axie Infinity</a>
                  <p class="tsc-details-proce mb-0">112.00 SNT</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="top-seller-wrapper mb-4">
              <div class="top-seller-img-wrapper text-center">
                <div class="top-seller-img">
                  <img src="../../assets/images/seller-img-7.jpg" class="w-100  toop-s" />
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="top-seller-inner-img" />
                </div>
              </div>

              <div class="top-seller-content d-flex justify-content-between">
                <div class="tsc mt-4">
                  <h3 class="fs-1">07</h3>
                </div>

                <div class="tsc-details d-flex justify-content-center flex-column">
                  <a href="#" class="text-decoration-none">NBA Top Shot</a>
                  <p class="tsc-details-proce mb-0">46.00 SNT</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="top-seller-wrapper mb-4">
              <div class="top-seller-img-wrapper text-center">
                <div class="top-seller-img">
                  <img src="../../assets/images/seller-img-8.jpg" class="w-100  toop-s" />
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="top-seller-inner-img" />
                </div>
              </div>

              <div class="top-seller-content d-flex justify-content-between">
                <div class="tsc mt-4">
                  <h3 class="fs-1">08</h3>
                </div>

                <div class="tsc-details d-flex justify-content-center flex-column">
                  <a href="#" class="text-decoration-none">Hashmasks</a>
                  <p class="tsc-details-proce mb-0">126.00 SNT</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="ANFT-MAIN-SEC">
      <div class="container">
        <div class="wallet-heading d-flex align-items-center justify-content-center mb-3">
          <h3 class="fs-1">EXCLUSIVE NFT DROPS</h3>
        </div>

        <div class="row justify-content-center">
          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div class="anft-wrapper mb-5">
              <div class="anft-top d-flex justify-content-between align-items-center">
                <div class="anft-logo d-flex align-items-center gap-3">
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="img-fluid" />
                  <!-- <h6 class="fs-5 mb-0">
                    <a href="#">Gucci Lucas</a>
                  </h6> -->
                </div>

                <div class="nft-dots">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots"
                    viewBox="0 0 16 16">
                    <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                  </svg>
                </div>
              </div>

              <div class="anft-img text-center">
                <img src="../../assets/images/nft-img-1.jpg" class="w-100 px-3" />
              </div>

              <div class="anft-card-footer-2">
                <h4 class="mt-2">RareVision</h4>

                <div class="anft-footer-price d-flex justify-content-between">
                  <p class="fs-6">
                    Price
                    <span>8.34 SNT</span>
                  </p>

                  <a href="#" class="fs-5 text-decoration-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                      class="bi bi-suit-heart-fill" viewBox="0 0 16 16">
                      <path
                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1" />
                    </svg>
                    230
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div class="anft-wrapper mb-5">
              <div class="anft-top d-flex justify-content-between align-items-center">
                <div class="anft-logo d-flex align-items-center gap-3">
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="img-fluid" />
                  <!-- <h6 class="fs-5 mb-0">
                    <a href="#">Gucci Lucas</a>
                  </h6> -->
                </div>

                <div class="nft-dots">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots"
                    viewBox="0 0 16 16">
                    <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                  </svg>
                </div>
              </div>

              <div class="anft-img text-center">
                <img src="../../assets/images/nft-img-2.jpg" class="w-100 px-3" />
              </div>

              <div class="anft-card-footer-2">
                <h4 class="mt-2">GenesisGems</h4>

                <div class="anft-footer-price d-flex justify-content-between">
                  <p class="fs-6">
                    Price
                    <span>10 SNT</span>
                  </p>

                  <a href="#" class="fs-5 text-decoration-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                      class="bi bi-suit-heart-fill" viewBox="0 0 16 16">
                      <path
                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1" />
                    </svg>
                    230
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div class="anft-wrapper mb-5">
              <div class="anft-top d-flex justify-content-between align-items-center">
                <div class="anft-logo d-flex align-items-center gap-3">
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="img-fluid" />
                  <!-- <h6 class="fs-5 mb-0">
                    <a href="#">Gucci Lucas</a>
                  </h6> -->
                </div>

                <div class="nft-dots">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots"
                    viewBox="0 0 16 16">
                    <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                  </svg>
                </div>
              </div>

              <div class="anft-img text-center">
                <img src="../../assets/images/nft-img-3.jpg" class="w-100 px-3" />
              </div>

              <div class="anft-card-footer-2">
                <h4 class="mt-2">PrimePixel</h4>

                <div class="anft-footer-price d-flex justify-content-between">
                  <p class="fs-6">
                    Price
                    <span>17.50 SNT</span>
                  </p>

                  <a href="#" class="fs-5 text-decoration-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                      class="bi bi-suit-heart-fill" viewBox="0 0 16 16">
                      <path
                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1" />
                    </svg>
                    230
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div class="anft-wrapper mb-5">
              <div class="anft-top d-flex justify-content-between align-items-center">
                <div class="anft-logo d-flex align-items-center gap-3">
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="img-fluid" />
                  <!-- <h6 class="fs-5 mb-0">
                    <a href="#">Gucci Lucas</a>
                  </h6> -->
                </div>

                <div class="nft-dots">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots"
                    viewBox="0 0 16 16">
                    <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                  </svg>
                </div>
              </div>

              <div class="anft-img text-center">
                <img src="../../assets/images/nft-img-4.jpg" class="w-100 px-3" />
              </div>

              <div class="anft-card-footer-2">
                <h4 class="mt-2">LegacyLoot</h4>

                <div class="anft-footer-price d-flex justify-content-between">
                  <p class="fs-6">
                    Price
                    <span>20.41 SNT</span>
                  </p>

                  <a href="#" class="fs-5 text-decoration-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                      class="bi bi-suit-heart-fill" viewBox="0 0 16 16">
                      <path
                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1" />
                    </svg>
                    230
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div class="anft-wrapper mb-5">
              <div class="anft-top d-flex justify-content-between align-items-center">
                <div class="anft-logo d-flex align-items-center gap-3">
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="img-fluid" />
                  <!-- <h6 class="fs-5 mb-0">
                    <a href="#">Gucci Lucas</a>
                  </h6> -->
                </div>

                <div class="nft-dots">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots"
                    viewBox="0 0 16 16">
                    <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                  </svg>
                </div>
              </div>

              <div class="anft-img text-center">
                <img src="../../assets/images/nft-img-5.jpg" class="w-100 px-3" />
              </div>

              <div class="anft-card-footer-2">
                <h4 class="mt-2">EliteEditions</h4>

                <div class="anft-footer-price d-flex justify-content-between">
                  <p class="fs-6">
                    Price
                    <span>5.92 SNT</span>
                  </p>

                  <a href="#" class="fs-5 text-decoration-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                      class="bi bi-suit-heart-fill" viewBox="0 0 16 16">
                      <path
                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1" />
                    </svg>
                    230
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div class="anft-wrapper mb-5">
              <div class="anft-top d-flex justify-content-between align-items-center">
                <div class="anft-logo d-flex align-items-center gap-3">
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="img-fluid" />
                  <!-- <h6 class="fs-5 mb-0">
                    <a href="#">Gucci Lucas</a>
                  </h6> -->
                </div>

                <div class="nft-dots">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots"
                    viewBox="0 0 16 16">
                    <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                  </svg>
                </div>
              </div>

              <div class="anft-img text-center">
                <img src="../../assets/images/nft-img-6.jpg" class="w-100 px-3" />
              </div>

              <div class="anft-card-footer-2">
                <h4 class="mt-2">LimitedLux</h4>

                <div class="anft-footer-price d-flex justify-content-between">
                  <p class="fs-6">
                    Price
                    <span>2.50 SNT</span>
                  </p>

                  <a href="#" class="fs-5 text-decoration-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                      class="bi bi-suit-heart-fill" viewBox="0 0 16 16">
                      <path
                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1" />
                    </svg>
                    230
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div class="anft-wrapper mb-5">
              <div class="anft-top d-flex justify-content-between align-items-center">
                <div class="anft-logo d-flex align-items-center gap-3">
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="img-fluid" />
                  <!-- <h6 class="fs-5 mb-0">
                    <a href="#">Gucci Lucas</a>
                  </h6> -->
                </div>

                <div class="nft-dots">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots"
                    viewBox="0 0 16 16">
                    <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                  </svg>
                </div>
              </div>

              <div class="anft-img text-center">
                <img src="../../assets/images/nft-img-7.jpg" class="w-100 px-3" />
              </div>

              <div class="anft-card-footer-2">
                <h4 class="mt-2">IconicImprints</h4>

                <div class="anft-footer-price d-flex justify-content-between">
                  <p class="fs-6">
                    Price
                    <span>15.34 SNT</span>
                  </p>

                  <a href="#" class="fs-5 text-decoration-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                      class="bi bi-suit-heart-fill" viewBox="0 0 16 16">
                      <path
                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1" />
                    </svg>
                    230
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div class="anft-wrapper mb-5">
              <div class="anft-top d-flex justify-content-between align-items-center">
                <div class="anft-logo d-flex align-items-center gap-3">
                  <img src="../../assets/images/nft-img/nft-top-2-cat.png" class="img-fluid" />
                  <!-- <h6 class="fs-5 mb-0">
                    <a href="#">Gucci Lucas</a>
                  </h6> -->
                </div>

                <div class="nft-dots">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-three-dots"
                    viewBox="0 0 16 16">
                    <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                  </svg>
                </div>
              </div>

              <div class="anft-img text-center">
                <img src="../../assets/images/nft-img-8.jpg" class="w-100 px-3" />
              </div>

              <div class="anft-card-footer-2">
                <h4 class="mt-2">SignatureSpectra</h4>

                <div class="anft-footer-price d-flex justify-content-between">
                  <p class="fs-6">
                    Price
                    <span>11.50 SNT</span>
                  </p>

                  <a href="#" class="fs-5 text-decoration-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                      class="bi bi-suit-heart-fill" viewBox="0 0 16 16">
                      <path
                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1" />
                    </svg>
                    230
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="blog-sec-main nft_padding">
      <div class="container">
        <div class="wallet-heading d-flex align-items-center justify-content-center mb-3">
          <h3 class="fs-1">Recent NFTS </h3>
        </div>

        <div class="row justify-content-center">
          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">
            <div class="blog-wrapper text-center mb-5">
              <div class="blog-nun">
                <div class="blog-img">
                  <img src="../../assets/images/blog-img-1.jpg" class="w-100" />
                </div>

                <div class="text-start mt-2">
                  <h4>
                    <a href="#" class="text-decoration-none text-white">Collectible NFTs</a>
                  </h4>
                </div>

                <div class="d-flex justify-content-between">
                  <div class="date d-flex gap-2 align-items-center">
                    <div class="date-img">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-calendar3" viewBox="0 0 16 16">
                        <path
                          d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                        <path
                          d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                      </svg>
                    </div>

                    <div class="date-heading">Feb 20 2024</div>
                  </div>

                  <div class="date d-flex gap-2 align-items-center">
                    <div class="date-img">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-person-circle" viewBox="0 0 16 16">
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                        <path fill-rule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
                      </svg>
                    </div>

                    <div class="date-heading">Jhon doe</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">
            <div class="blog-wrapper text-center mb-5">
              <div class="blog-nun">
                <div class="blog-img">
                  <img src="../../assets/images/blog-img-2.jpg" class="w-100" />
                </div>

                <div class="text-start mt-2">
                  <h4>
                    <a href="#" class="text-decoration-none text-white">Fashion & Luxury NFTs</a>
                  </h4>
                </div>

                <div class="d-flex justify-content-between">
                  <div class="date d-flex gap-2 align-items-center">
                    <div class="date-img">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-calendar3" viewBox="0 0 16 16">
                        <path
                          d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                        <path
                          d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                      </svg>
                    </div>

                    <div class="date-heading">Feb 20 2024</div>
                  </div>

                  <div class="date d-flex gap-2 align-items-center">
                    <div class="date-img">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-person-circle" viewBox="0 0 16 16">
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                        <path fill-rule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
                      </svg>
                    </div>

                    <div class="date-heading">Anna</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">
            <div class="blog-wrapper text-center mb-5">
              <div class="blog-nun">
                <div class="blog-img">
                  <img src="../../assets/images/blog-img-3.jpg" class="w-100" />
                </div>

                <div class="text-start mt-2">
                  <h4>
                    <a href="#" class="text-decoration-none text-white">Content & Media NFTs</a>
                  </h4>
                </div>

                <div class="d-flex justify-content-between">
                  <div class="date d-flex gap-2 align-items-center">
                    <div class="date-img">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-calendar3" viewBox="0 0 16 16">
                        <path
                          d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                        <path
                          d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                      </svg>
                    </div>

                    <div class="date-heading">Feb 20 2024</div>
                  </div>

                  <div class="date d-flex gap-2 align-items-center">
                    <div class="date-img">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-person-circle" viewBox="0 0 16 16">
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                        <path fill-rule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
                      </svg>
                    </div>

                    <div class="date-heading">Antonia</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

//   const categorySlider = new categorySlider('.categorySlider', {
//   autoplay: {
//     delay: 5000,
//   },
// });

export default {
  name: "NFTView",
  data() {
    return {
      breakpoints2: {
        0: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        700: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "center",
        },
      },
      breakpoints3: {
        0: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        700: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "center",
        },
      },
      walletoption: [
        {
          img: "metamax.png",
          para: "MetaMax",
        },

        {
          img: "binance.png",
          para: "Binance",
        },

        {
          img: "formatic.png",
          para: "Formatic",
        },

        {
          img: "autherium.png",
          para: "Autherum",
        },

        {
          img: "coinbase.png",
          para: "Coinbase",
        },

        {
          img: "portise.png",
          para: "Portis",
        },
      ],
      AuctionSlider: [
        {
          img: "nft-top-img.png",
          image: "wallet-img-1.jpg",
          name: "Marco",
          link: "#",
          title: "SecureVault",
          price: "1.35 SNT",
          likeLink: "#",
          likes: 230,
        },
        {
          img: "nft-top-img.png",
          image: "wallet-img-2.jpg",
          name: "Brayden",
          link: "#",
          title: "FortifyWallet",
          price: "5.00 SNT",
          likeLink: "#",
          likes: 230,
        },
        {
          img: "nft-top-img.png",
          image: "wallet-img-3.jpg",
          name: "Amelia",
          link: "#",
          title: "ShieldedSafe",
          price: "2.50 SNT",
          likeLink: "#",
          likes: 230,
        },
        {
          img: "nft-top-img.png",
          image: "wallet-img-4.jpg",
          name: "Elena",
          link: "#",
          title: "GuardianGuard",
          price: "10.00 SNT",
          likeLink: "#",
          likes: 230,
        },
        {
          img: "nft-top-img.png",
          image: "wallet-img-5.jpg",
          name: "Jhon Doe",
          link: "#",
          title: "ArmorWallet",
          price: "8.62 SNT",
          likeLink: "#",
          likes: 230,
        },
      ],

      categorySlider: [
        {
          img: "digital-art-monkey.jpg",
        },

        // {
        //   img: "browse-img-2.jpg",
        // },
        // {
        //   img: "browse-img-3.jpg",
        // },

        // {
        //   img: "browse-img1.jpg",
        // },
      ],

      categorySlider2: [
        {
          img: "music-img.jpg",
        },


      ],

      categorySlider3: [
        {
          img: "domain-name.jpg",
        },

        // {
        //   img: "browse-img-8.jpg",
        // },
        // {
        //   img: "browse-img-9.jpg",
        // },

        // {
        //   img: "browse-img-7.jpg",
        // },
      ],

      categorySlider4: [
        {
          img: "sports.jpg",
        },

        // {
        //   img: "browse-img-11.jpg",
        // },
        // {
        //   img: "browse-img-12.jpg",
        // },

        // {
        //   img: "browse-img-10.jpg",
        // },
      ],

      categorySlider5: [
        {
          img: "utilities.jpg",
        },

        // {
        //   img: "browse-img-14.jpg",
        // },
        // {
        //   img: "browse-img-15.jpg",
        // },

        // {
        //   img: "browse-img-13.jpg",
        // },
      ],

      categorySlider6: [
        {
          img: "all-nft.jpg",
        },

        // {
        //   img: "browse-img-17.jpg",
        // },
        // {
        //   img: "browse-img-18.jpg",
        // },

        // {
        //   img: "browse-img-16.jpg",
        // },
      ],
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Swiper,
    SwiperSlide,
  },
};
</script>

<style>
.nft-banner {
  min-height: 90vh;

}

.nft_padding {
  padding: 130px 0px 133px 0px;
}

.banner-content-area h1 {
  font-size: 71px;
  font-weight: 800;
}

.banner-content-area h1 span {
  color: #f3a735;
}

.banner-btn .explore {
  text-decoration: none;
  color: white;
  background-image: linear-gradient(264.28deg, #d846a5 -38.2%, #4d0eae 103.12%);
  padding: 14px 36px;
  border-radius: 30px;

  transition: all 0.3s ease;
}

.banner-btn .explore:hover {
  transform: translateY(10px);
}

.banner-btn .create {
  text-decoration: none;
  color: white;
  border: 2px solid white;
  background-color: black;
  padding: 14px 36px;
  border-radius: 30px;
  transition: all 0.3s ease;
}

.banner-btn .create:hover {
  transform: translateY(10px);
}

.swipper-inner {
  padding: 15px;
  background-color: #000;
  border: 2px solid transparent;
  border-image: linear-gradient(125deg, #ffe53b 0%, #ff2525 37%, #fd00ff 100%);
  border-image-slice: 1;
  width: 400px;
  margin: auto;
}

.swipper-top .swipper-logo img {
  width: 40px;
}

.swipper-logo h6 a {
  text-decoration: none;
  color: white;
}

.swipper-top {
  background-color: black;
  max-width: 400px;
  padding: 0px 11px;
}

.nft-dots svg {
  width: 45px;
}

.nft-footer-price span {
  color: #e6b03d;
}

.nft-footer-price a {
  color: #ffffff;
}

/**==========banner section css end===============**/
/**==========wallet options css start=============**/


.wallet-box {
  border: 2px solid gray;
  padding: 20px;
  border-radius: 10px;
}

.nft-card-footer-2 {
  padding: 0px 20px;
}

.count-down-class {
  background-color: #e6b03d;
  border-radius: 30px;
  padding: 15px;
}

.nft-card-img {
  position: relative;
  padding: 0px 10px;
}

.nft-card-img img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.count-down-class {
  position: absolute;
  width: 93%;
  bottom: 9px;
}

.swipper-inner-2 {
  max-width: 100%;
  background-color: #000;
  border: 2px solid transparent;
  border-image: linear-gradient(125deg, #ffe53b 0%, #ff2525 37%, #fd00ff 100%);
  border-image-slice: 1;
  transition: all 0.3s ease;
}

.swipper-inner-2:hover {
  transform: translateY(10px);
}

/**==========wallet options css end=============**/

/**==========browse category css start========**/
/* .browser-slider-wrapper.browse-swiper img {
  border:none;
} */

.browser-category-wrapper {
  background-color: #000;
  border: 2px solid transparent;
  border-image: linear-gradient(125deg, #ffe53b 0%, #ff2525 37%, #fd00ff 100%);
  border-image-slice: 1;
  padding: 20px 30px;
  transition: all 0.3s ease;
}

.browser-category-wrapper:hover {
  transform: translateY(10px);
}

.browse-swiper img {
  object-fit: cover;
}

/**==========browse category css end========**/

/**========top seller section css start=====**/

.top-seller-img {
  position: relative;
}

.top-seller-img .toop-s {
  height: 131px !important;
  object-fit: cover !important;
}

.top-seller-inner-img {
  position: absolute;
  top: 90px;
  left: 45px;
  width: 47px;
}

.tsc-details a {
  color: white;
  font-size: 20px;
}

.tsc-details-proce {
  color: #e6b03d;
}

.top-seller-wrapper {
  background-color: #000;
  border: 2px solid transparent;
  border-image: linear-gradient(125deg, #ffe53b 0%, #ff2525 37%, #fd00ff 100%);
  border-image-slice: 1;
  padding: 20px;

  transition: all 0.3s ease;
}

.top-seller-wrapper:hover {
  transform: translateY(10px);
}

.top-seller-content {
  padding: 0px 16px;
}

/**========top seller section css end=====**/
/**============anft section css strat=========**/
.anft-logo img {
  width: 40px;
}

.anft-logo h6 a {
  text-decoration: none;
  color: white;
}

.anft-top {
  background-color: black;
  max-width: 400px;
  padding: 0px 11px;
}

.anft-wrapper {
  max-width: 100%;
  background-color: #000;
  border: 2px solid transparent;
  border-image: linear-gradient(125deg, #ffe53b 0%, #ff2525 37%, #fd00ff 100%);
  border-image-slice: 1;
  transition: all 0.3s ease;
}

.anft-wrapper:hover {
  transform: translateY(10px);
}

.anft-card-footer-2 {
  padding: 0px 20px;
}

.anft-footer-price a {
  color: white;
}

/**============anft section css end=========**/

/**==========blog section css start============**/

.blog-wrapper {
  background-color: #000;
  border: 2px solid transparent;
  border-image: linear-gradient(125deg, #ffe53b 0%, #ff2525 37%, #fd00ff 100%);
  border-image-slice: 1;
  padding: 20px;

  transition: all 0.3s ease;
}

.blog-content h4 a {
  color: white;
}

.blog-content h4 {
  font-size: 30px;
}

.blog-content {
  padding: 0px 30px;
}

.meta-imfo {
  padding: 0px 30px;
}

.blog-wrapper:hover {
  transform: translateY(10px);
}

/**==========blog section css end============**/

@media all and (min-width: 1200px) and (max-width: 1400px) {
  .top-seller-content {
    padding: 0px 0px;
  }

  .top-seller-inner-img {
    top: 87px;
    left: 16px;
    width: 44px;
  }
}

@media all and (min-width: 992px) and (max-width: 1199px) {
  .banner-content-area h1 {
    font-size: 60px;
  }

  .top-seller-inner-img {
    top: 65px;
    left: 10px;
    width: 40px;
  }

  .top-seller-content {
    padding: 0px 0px;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .swipper-inner {
    width: 447px;
  }

  .banner-content-area h1 {
    font-size: 64px;
  }

  .top-seller-inner-img {
    top: 63px;
    left: 19px;
    width: 39px;
  }

  .top-seller-content {
    padding: 0px 0px;
  }

  .anft-card-footer-2 {
    padding: 0px 40px;
  }
}

@media all and (min-width: 576px) and (max-width: 767px) {
  .banner-content-area h1 {
    font-size: 38px;
  }

  .top-seller-wrapper {
    min-height: 250px;
  }

  .top-seller-content {
    padding: 0px 2px;
    margin-top: 34px;
  }

  .top-seller-inner-img {
    top: 78px;
    left: 23px;
  }

  .anft-card-footer-2 {
    padding: 0px 72px;
  }
}

@media all and (min-width: 320px) and (max-width: 575px) {
  .banner-content-area h1 {
    font-size: 38px;
  }

  .swipper-inner {
    width: 288px;
  }

  .blog-content h4 {
    font-size: 18px;
  }

  .top-seller-content {
    flex-direction: column;
  }

  .tsc-details a {
    font-size: 23px;
  }

  .top-seller-inner-img {
    top: 52px;
    left: 21px;
    width: 34px;
  }

  .top-seller-content {
    padding: 0px 0px;
  }
}
</style>
